import React from "react";

const Gratis = () => {
    window.location.replace("//www.quivon.app/gratis")
    return (
        <div>
        </div>
    )
}

export default Gratis;