import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { links_doubts } from '../Help/links_doubts';

import desktop from '../../assets/icons/iconsHelp/icon2.svg'
import mobile from '../../assets/icons/iconsHelp/icon1.svg'

import { useMediaQuery } from '@mui/material'

import './styles.scss'

import logo from '../../assets/icons/favicon.png'
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const StyledVideo = styled(ReactPlayer)`
    margin-top: 5%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`


const Helpinfo = () => {
    const { id } = useParams();
    const [doubtData, setDoubtData] = useState(null);
    const isMobile = useMediaQuery('(max-width: 600px)')
    const hasMobileVideos = links_doubts.some((item) => item.doubts.some((doubt) => doubt.videos && doubt.videos.mobile));
    const [selectedButton, setSelectedButton] = useState(
        hasMobileVideos ? 'mobile' : 'desktop'
    );

    useEffect(() => {
        const filteredDoubt = links_doubts.reduce((foundDoubt, question) => {
            const filteredDoubt = question.doubts.find((doubt) => doubt.link === id);
            return filteredDoubt ? filteredDoubt : foundDoubt;
        }, null);

        if (filteredDoubt) {
            setDoubtData(filteredDoubt);
        }
    }, [id]);

    useEffect(() => {
        const hasMobileVideos = links_doubts.some((item) => item.doubts.some((doubt) => doubt.videos.mobile  ));
        setSelectedButton(hasMobileVideos ? 'mobile' : 'desktop');
    }, [])

    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };

    return (
        <div className='container_info'>
            <div className='header'>
                <div className='left'>
                    <img src={logo} alt="logotipo" className='logotipo' />
                    <Link to="/help" style={{ textDecoration: 'none', color: '#000' }}><h1>CENTRAL DE AJUDA</h1></Link>
                </div>
            </div>

            {doubtData ? (
                <div className='section_infos'>
                    <h1 dangerouslySetInnerHTML={{ __html: doubtData.name }} style={{ textAlign: ' center', paddingBottom: '2%', width: '90%' }}>
                    </h1>

                    <h3 dangerouslySetInnerHTML={{ __html: doubtData.text }} style={{ width: '80%', fontWeight: 100 }}>
                    </h3>


                    <div className="infos">
                        <ul>
                            {doubtData.steps.map((step, index) => (
                                <li>
                                    <h4 className='text_help_information' key={index} dangerouslySetInnerHTML={{ __html: step }}></h4>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='container_video'>
                        <div className='video_type'>
                            <p style={{ marginTop: 5 }}>instruções para:</p>
                            {doubtData.videos.desktop &&
                                <button onClick={() => handleButtonClick('desktop')}>
                                    <img style={{ height: 25, border: 'none', background: 'rgb(79, 114, 252)', borderRadius: 15, padding: 2, margin: '2.5px' }} src={desktop} />
                                </button>

                            }
                            {doubtData.videos.mobile &&
                                <button onClick={() => handleButtonClick('mobile')}>
                                    <img style={{ height: 25, border: 'none', background: 'rgb(79, 114, 252)', borderRadius: 15, padding: 2, margin: '2.5px' }} src={mobile} />
                                </button>
                            }
                        </div>
                        <h1>{selectedButton}</h1>
                        <div style={{ width: '100%' }}>
                            {selectedButton === 'desktop' && doubtData.videos.desktop && (
                                <StyledVideo
                                    width={isMobile ? '100%' : '640px'}
                                    url={doubtData.videos.desktop}

                                />
                            )}

                            {selectedButton === 'mobile' && doubtData.videos.mobile && (
                                <StyledVideo
                                    width={isMobile ? '100%' : '640px'}
                                    url={doubtData.videos.mobile}
                                />
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div>Dúvida não encontrada.</div>
            )}
        </div>
    );
};



export default Helpinfo;
