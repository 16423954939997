import React from "react";

import './SideDrawer.css'
import camera from "../../assets/icons/videoCamera.svg";
import administration from "../../assets/icons/administration.svg";
import quivonLogo from "../../assets/quivon.svg";

const sideDrawer = props => (
    <nav className="side-drawer">
        <div className="drawer__navigation-items">

            <div className="drawer__links">
                <div className="drawer__logo" onClick={props.drawerClickHandler}>
                    <img src={quivonLogo} alt='logo'/>
                    <span>x</span>
                </div>
                <div className="drawer__link">
                    <a href="#benefits">
                        Benefícios
                    </a>
                </div>
                <div className="drawer__link">
                    <a href="#functionalities">
                        Funcionalidades
                    </a>
                </div>
                <div className="drawer__link">
                    <a href="#download__app">
                        Baixe o app
                    </a>
                </div>
            </div>

            <ul>
                <li className="demonstration">
                    <button>
                        <img src={camera} alt="Camera"/>
                        <a target="_blank" rel="noreferrer" href="https://calendly.com/comercialquivon/30min?month=2021-10">Agendar demonstração</a>
                    </button>
                </li>
                <li className="administration">
                    <button>
                        <img src={administration} alt="Administração"/>
                        <a target="_blank" rel="noreferrer" href="https://partners.quivon.com.br">Área administrativa</a>
                    </button>
                </li>
            </ul>
        </div>
    </nav>
);

export default sideDrawer