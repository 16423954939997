
import React, {Component} from "react";

import './Benefits.css';

import chat from '../../assets/icons/chat.png'
import persons from '../../assets/icons/persons.png'
import lock from '../../assets/icons/user-lock.png'
import eye from '../../assets/icons/eye.png'
import video from '../../assets/icons/video.png'
import pencil from '../../assets/icons/pencil-alt.png'
import card from '../../assets/icons/credit-card.png'
import hands from '../../assets/icons/hands-helping.png'

class BenefitsPersonal extends Component {

    render() {

        const { items1, items2 } = this.state;

        return (
            <div className="cards__benefits">
                <div className="cards">
                    {items1.map(item =>
                        <div className="card">
                            <img src={item.image} alt="phone"/>
                            <h2>{item.title}</h2>
                            <p>
                                {item.text}
                            </p>
                        </div>
                    )}
                </div>
                <div className="cards">
                    {items2.map(item =>
                        <div className="card">
                            <img src={item.image} alt="phone"/>
                            <h2>{item.title}</h2>
                            <p>
                                {item.text}
                            </p>
                        </div>
                    )}
                </div>
            </div>

        )
    }

    state = {
        items1: [
            {
                id: 1,
                title: 'Acompanhamento próximo',
                text: 'Obtenha, na área do aluno, todo o histórico de modo fácil e intuitivo. O aluno tem direito a enviar atualizações conforme o tempo de intervalo definido pelo profissional na plataforma. ',
                image: eye,
            },
            {
                id: 2,
                title: 'Retenção de Alunos',
                text: 'Saiba quais dos seus alunos estão aptos ou não para atuar antes de uma possível desistência. Alunos entrando e se tornando motivadores, esse é o nosso objetivo!',
                image: lock,
            },
            {
                id: 3,
                title: 'Praticidade no contato',
                text: 'Converse com seus alunos em apenas um clique. Seja pelo Telegram ou pelo Whatsapp, filtre os alunos por diversos cenários.',
                image: chat,
            },
            {
                id: 4,
                title: 'Vídeos de treinamento',
                text: 'Economize tempo com um banco de 600 vídeos de treinamento gravados por nossa equipe. Recursos como esse valorizam a sua consultoria.',
                image: video,
            },
        ],
        items2: [
            {
                id: 1,
                title: 'Motivação e integração',
                text: 'Motive seus alunos a se manterem ativos na prática de exercícios físicos e, consequentemente, na consultoria através da criação de projetos e grupos de treinamento. Somos uma plataforma de treinos completa.',
                image: persons,
            },
            {
                id: 2,
                title: 'Customização',
                text: 'Customize o seu atendimento a partir de: tempo de atualização do aluno, perguntas no relatório de anamnese, tempo máximo de atendimento, preços e formas de pagamento.',
                image: pencil,
            },
            {
                id: 3,
                title: 'Parceria',
                text: 'Não somos apenas uma plataforma, somos a parceira que tem como objetivo mudar vidas. Conte com estratégias diferenciadas de engajamento para obtenção de alunos, além de treinamentos, programas de aceleração e marketing. O seu sucesso é o nosso sucesso.',
                image: hands,
            },
            {
                id: 4,
                title: 'Gestão de Pagamentos',
                text: 'Realize de forma eficiente a gestão de pagamentos diretamente na plataforma. Este processo é feito a partir de uma conta digital, podendo transferir para qualquer instituição da sua escolha. ',
                image: card,
            },
        ]
    }

}

export default BenefitsPersonal

