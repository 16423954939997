import React from 'react'
import QuivonLogo from "../assets/icons/quivon 1.svg";

const Facebook = () => {
    return (
        <main style={{padding: 20, display: "flex", flexDirection: "column"}}>
            <img src={QuivonLogo} style={{alignSelf: "center"}} alt="logo"/>
            <section style={{padding: 20}}>
                <h3>
                    Como Solicitar a Exclusão dos Seus Dados
                </h3>
                <p>
                    Em conformidade com os requisitos do Facebook, fornecemos uma maneira fácil para que você solicite a
                    exclusão dos seus dados. Siga os passos abaixo para excluir os dados armazenados em nossa
                    plataforma:
                </p>
                <div style={{margin: 24}}>
                    <p>1. <strong>Acesse o Quivon For Partners Web:</strong></p>
                    <p style={{display: "flex", alignItems: "center"}}><h1
                        style={{marginTop: -16, fontSize: 32, marginLeft: 16}}>.</h1> Faça login em sua conta Quivon
                        através da nossa plataforma web.
                    </p>
                    <p>2. <strong>Navegue para Configurações:</strong></p>
                    <p style={{display: "flex", alignItems: "center"}}><h1
                        style={{marginTop: -16, fontSize: 32, marginLeft: 16}}>.</h1> No menu principal, clique em
                        "Configurações".
                    </p>
                    <p>3. <strong>Gerencie o Vínculo com o Facebook:</strong></p>
                    <p style={{display: "flex", alignItems: "center"}}><h1
                        style={{marginTop: -16, fontSize: 32, marginLeft: 16}}>.</h1> Dentro das configurações,
                        selecione a opção "Vínculo com Facebook".
                    </p>
                    <p>4. <strong>Solicite a Exclusão dos Dados:</strong></p>
                    <p style={{display: "flex", alignItems: "center"}}><h1
                        style={{marginTop: -16, fontSize: 32, marginLeft: 16}}>.</h1> Clique no botão "Excluir Dados do
                        Facebook". Isso iniciará o processo de exclusão de todos os dados que foram obtidos através da
                        sua conexão com o Facebook.
                    </p>
                </div>
                <p>
                    <strong>Nota:</strong> A exclusão dos seus dados pode levar alguns momentos para ser processada. Uma
                    vez concluído,
                    você
                    receberá uma confirmação de que seus dados foram removidos com sucesso.
                    Se precisar de mais assistência ou tiver dúvidas sobre o processo, entre em contato com nossa equipe
                    de
                    suporte.
                </p>
            </section>
        </main>
    )
}

export default Facebook