import React from 'react';
import {Route, Routes} from 'react-router-dom';

import Home from './containers/home';
import Politica from './containers/politica';
import Termos from './containers/termos';
import Analise from './containers/analise'
import Tifranco from './containers/tifranco'
import Ulysses from './containers/ulysses'
import UlyssesCode from './containers/ulysses_code'
import Quivon from './containers/quivon'
import Whitelabel from "./containers/whitelabel";
import Manuais from './containers/manuais'
import Help from './containers/Help/help'
import HelpInfo from './containers/HelpInfo/helpinfo';
import Gratis from "./containers/gratis";
import Features from "./containers/features";
import Payments from "./containers/payments";
import ForgotPassword from "./containers/forgot_password";
import Facebook from "./containers/facebook";

const Main = () => {
    return (
        <Routes>
            <Route exact path='/' element={<Home/>}/>
            <Route exact path='/politica' element={<Politica/>}/>
            <Route exact path='/termo' element={<Termos/>}/>
            <Route exact path='/analise' element={<Analise/>}/>
            <Route exact path='/ti-franco-code' element={<Tifranco/>}/>
            <Route exact path='/ulyssespedro' element={<Ulysses/>}/>
            <Route exact path='/ulysses_code' element={<UlyssesCode/>}/>
            <Route exact path='/quivon' element={<Quivon/>}/>
            <Route path='/:id' element={<Whitelabel/>}/>
            <Route path='/manuais' element={<Manuais/>}/>
            <Route path='/help' element={<Help/>}/>
            <Route path='/help/:id' element={<HelpInfo/>}/>
            <Route path={"/payments"} element={<Payments/>}/>
            <Route path={"/recover"} element={<ForgotPassword/>}/>
            <Route path='/gratis' element={<Gratis/>}/>
            <Route path='/features' element={<Features/>}/>
                <Route path='/facebook' element={<Facebook/>}/>
        </Routes>
    );
}

export default Main;