import React, { useState } from "react";

const Manuais = () => {

    const listItemsManual = [
        {
            id: 1,
            name: 'Cadastro Feminino',
            pdf: 'https://quivon-public.s3.amazonaws.com/Manual-Feminino.pdf'
        },
        {
            id: 2,
            name: 'Cadastro Masculino',
            pdf: 'https://quivon-public.s3.amazonaws.com/Manual-Masculino.pdf'
        },
        {
            id: 3,
            name: 'Branding Book',
            pdf: 'https://forms.gle/Jf8ihc3w3VgrvHMq5'
        },
        {
            id: 4,
            name: 'Criação Projeto',
            pdf: 'https://quivon-public.s3.amazonaws.com/Criacao-Projeto.pdf'
        },

    ]

    return (
        <div className="container_manual">
            <h1>Manuais</h1>
            <div className="container_manuais">

                {listItemsManual.map((manual) => {
                    return (
                        <div className="card-manual">
                            <h1>{manual.name}</h1>
                            <a className="manual-text-download" href={manual.pdf} download={manual.pdf} target="_blank">Download</a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Manuais