import React from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import quivonLogo from '../assets/quivon.svg'
import './payments.scss'
import creditCard from '../assets/icons/credit-card.svg'
import pix from '../assets/icons/dollar-sign.svg'
import errorIcon from '../assets/icons/errorIcon.svg'
import helpCircle from '../assets/icons/help-circle.svg'
import iconClose from '../assets/icons/icon_close.svg'
import CardHelp from '../assets/icons/shutterstock_1154942398-1 1.png'
import Cell from "../assets/icons/iPhone 12 Mockup label.svg"
import {CircularProgress} from '@mui/material';
import axios from "axios";
import Modal from "react-modal"

const Payments = () => {
    const [paymentMethod, setPaymentMethod] = React.useState('CREDIT_CARD')
    const [personalData, setPersonalData] = React.useState({
        name: '',
        email: '',
        cpf: ''
    })
    const [paymentData, setPaymentData] = React.useState({
        cardNumber: '',
        cardName: '',
        cardExpiration: '',
        cardSecurityCode: ''
    })
    const [dispatch, setDispatch] = React.useState({
        loading: false,
        error: false,
        success: false,
        terms: false
    })
    const [touched, setTouched] = React.useState({
        name: false,
        email: false,
        cpf: false,
        cardNumber: false,
        cardName: false,
        cardExpiration: false,
        cardSecurityCode: false
    })
    const [error, setError] = React.useState({
        title: '',
        message: ''
    })
    const [success, setSuccess] = React.useState({})
    const [cardSuccess, setCardSuccess] = React.useState(false)
    const [installment, setInstallment] = React.useState(1)
    const [information, setInformation] = React.useState(false)
    const navigate = useNavigate();

    const {state} = useLocation();

    let cards = {
        "AMEX": /^3[47][0-9]{13}$/,
        "AURA": /^50[0-9]{14}$/,
        "BANESE CARD": /^636117[0-9]{10}$/,
        "CABAL": /^(60420[1-9]|6042[1-9][0-9]|6043[0-9]{2}|604400)[0-9]{10}$/,
        "DINERS": /^(30[0-5]|36|38)[0-9]{12}$/,
        "DISCOVER": /^(6011|65|64[4-9]|622[1-9])[0-9]{12,15}$/,
        "ELO": /^(40117[8-9]|438935|451416|457393|504175|627780|636297|636368|6504|6505|6509|6516|6550|6553|6556|6564|50670[7-9]|50671[0-9]|50672[0-9]|50673[0-9]|50674[0-9]|50675[0-9]|50676[0-9]|50677[0-9]|50678[0-9]|50679[0-9])[0-9]{10}$/,
        "FORT BRASIL": /^(628128|628282|628361|628862|628924|628984|629031)[0-9]{10}$/,
        "GrandCard": /^6011\d{12}$/,
        "JCB": /^(?:2131|1800|35\d{3})\d{11}$/,
        "MASTERCARD": /^5[1-5][0-9]{14}$/,
        "VISA": /^4[0-9]{15}$/,
        "UNKNOWN": /^\d{16}$/ //unknown is the default value
    };

    function validateCard(nr, cartoes) {
        for (let cartao in cartoes) if (nr.match(cartoes[cartao])) return cartao;
        return false;
    }

    const mask = (v) => {
        v = v.replace(/\D/g, "")

        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

        return v
    }

    function validateCPF(cpf) {
        cpf = cpf.replace(/\D/g, '');

        if (cpf.length !== 11) {
            return false;
        }

        if (/^(\d)\1{10}$/.test(cpf)) {
            return false;
        }

        let soma = 0;
        for (let i = 0; i < 9; i++) {
            soma += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let primeiroDigito = 11 - (soma % 11);
        if (primeiroDigito === 10 || primeiroDigito === 11) {
            primeiroDigito = 0;
        }

        if (parseInt(cpf.charAt(9)) !== primeiroDigito) {
            return false;
        }

        soma = 0;
        for (let i = 0; i < 10; i++) {
            soma += parseInt(cpf.charAt(i)) * (11 - i);
        }
        let segundoDigito = 11 - (soma % 11);
        if (segundoDigito === 10 || segundoDigito === 11) {
            segundoDigito = 0;
        }

        return parseInt(cpf.charAt(10)) === segundoDigito;


    }

    function onlyNumber(evt) {
        let theEvent = evt
        let key = theEvent.keyCode || theEvent.which
        key = String.fromCharCode(key)
        let regex = /^[0-9]+$/
        if (!regex.test(key)) {
            theEvent.returnValue = false
            if (theEvent.preventDefault) theEvent.preventDefault()
        }
    }

    function addSpaces(num) {
        const groups = num.replace(/\s/g, '').match(/.{1,4}/g);

        if (groups) {
            return groups.join(' ');
        }
        return num;
    }

    function addBar(v) {
        v = v.replace(/\D/g, "")
        v = v.replace(/(\d{2})(\d)/, "$1/$2")
        return v
    }

    function takeDataBefore(date) {
        const parts = date.split('/');
        if (parts.length === 2) {
            return parts[0];
        } else {
            return null;
        }
    }

    function takeDataAfter(date) {
        const parts = date.split('/');
        if (parts.length === 2) {
            return parts[1];
        } else {
            return null;
        }
    }

    const formatCurrency = (value) => {
        return Intl.NumberFormat('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value * 0.01)
    }

    async function getLink() {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/payments`, {
                "billingType": paymentMethod,
                "billingPlanId": state?.plan?.id,
                "cpf": personalData.cpf.replace(/[.-]/g, ''),
                "email": personalData.email,
                "name": personalData.name,
                "installments": paymentMethod === 'CREDIT_CARD' ? installment : undefined,
                "creditCard": paymentMethod === 'CREDIT_CARD' ? {
                    "creditCardBrand": validateCard(paymentData.cardNumber.replace(/\s/g, ''), cards).toUpperCase(),
                    "creditCardCcv": paymentData.cardSecurityCode,
                    "creditCardExpiryYear": `20${takeDataAfter(paymentData.cardExpiration)}`,
                    "creditCardExpiryMonth": takeDataBefore(paymentData.cardExpiration),
                    "creditCardNumber": paymentData.cardNumber.replace(/\s/g, ''),
                    "creditCardHolderName": paymentData.cardName
                } : undefined,
            },
            {
                headers: {
                    'tenant': 'quivon',
                }
            })

        return response.data;
    }

    const regexEmail = /\S+@\S+\.\S+/;

    function getMobileOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
            return 'iOS';

        } else if (userAgent.match(/Android/i)) {

            return 'Android';
        } else {
            return 'unknown';
        }
    }

    function durationToString(duration) {
        switch (duration) {
            case 1:
                return 'Mensal'
            case 3:
                return 'Trimestral'
            case 6:
                return 'Semestral'
            case 12:
                return 'Anual'
            default:
                return `${duration} meses`
        }
    }

    React.useEffect(() => {
        setDispatch({...dispatch, terms: false})
    }, [paymentMethod, error, dispatch.error, dispatch.success, cardSuccess])

    React.useEffect(() => {
        window.scrollTo(0, 0)
        if (!state?.plan) {
            navigate('/')
        }
    }, [])

    return (
        <main>
            <nav>
                <img src={quivonLogo} alt="logo"/>
                <h3>
                    <span>|</span>
                    Pagamentos
                </h3>
            </nav>

            <div className="container">
                {dispatch.loading ?
                    <div className="left">
                        <div className="card" style={{alignItems: "center", padding: '108px 0'}}>
                            <CircularProgress size={157} style={{color: "#2CD0B1"}}/>
                            <h1 style={{marginTop: 20}}>Aguarde um instante...</h1>
                            <p>Estamos efetuando sua assinatura</p>
                        </div>
                    </div>
                    :
                    dispatch.error ?
                        <div className="left">
                            <div className="card" style={{alignItems: "center", padding: '108px 0'}}>
                                <img src={errorIcon} alt="" style={{width: 160, height: 153}}/>
                                <h1 style={{marginTop: 20}}>{error.title}</h1>
                                <p>{error.message}</p>

                                <div className={"input__container"} style={{maxWidth: 320, marginTop: 20}}>
                                    <button onClick={() => setDispatch(prevState => {
                                        return {...prevState, error: false}
                                    })}>
                                        Tentar novamente
                                    </button>
                                </div>
                            </div>
                        </div> :
                        cardSuccess ?
                            <div className="left">
                                <div className="card" style={{alignItems: "center", padding: '108px 0'}}>
                                    <h1 style={{marginTop: 20}}>Deu tudo certo com a sua assinatura!</h1>
                                    <p>O seu personal já recebeu a sua solicitação, e em breve você receberá por e-mail
                                        as orientações necessárias para dar início à sua consultoria.</p>

                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        padding: "0 50px",
                                        maxWidth: 958,
                                        height: 300,
                                        background: "linear-gradient(182deg, #235CDF 65.32%, #2DC4B9 98.66%)",
                                        marginTop: 100
                                    }}>
                                        <img src={Cell} alt="" style={{
                                            width: 153,
                                            height: 300,
                                            marginRight: 70,
                                            display: window.innerWidth < 768 ? "none" : "flex"
                                        }}/>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            fontFamily: "Montserrat",
                                            fontStyle: "normal",
                                            lineHeight: "44px"
                                        }}>
                                            <h1 style={{
                                                fontWeight: 700,
                                                fontSize: 32,
                                                color: "#FFF",
                                                marginBottom: 20,
                                                textAlign: "start"
                                            }}>Baixe nosso app</h1>
                                            <p style={{fontWeight: 400, fontSize: 24, color: "#FFF", marginBottom: 20}}>
                                                E atinja os seus objetivos e
                                            </p>
                                            <p style={{fontWeight: 400, fontSize: 24, color: "#FFF", marginBottom: 30}}>
                                                tenha uma vida mais saudável
                                            </p>
                                            <div className="input__container">
                                                <button
                                                    onClick={() => {
                                                        if (getMobileOperatingSystem() === "iOS") {
                                                            window.open('https://apps.apple.com/br/app/quivon/id1507700739', '_blank')
                                                        } else {
                                                            window.open('https://play.google.com/store/apps/details?id=br.com.motivapp.motiva&hl=pt', '_blank')
                                                        }
                                                    }}
                                                >
                                                    Baixar App
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            dispatch.success ?
                                <div className="left">
                                    <div className="card" style={{alignItems: "center", padding: '108px 0'}}>
                                        <h1 style={{marginTop: 20}}>Tudo certo! Agora é só efetuar o pagamento</h1>
                                        <p>Após a confirmação do pagamento você receberá as instruções de acesso por
                                            e-mail.</p>

                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <img src={success.encodedImage} style={{width: 336, height: 336}}
                                                 alt="QRCode"/>
                                            <div className="pix__data">
                                                <h1 style={{textAlign: "start", marginBottom: 25}}>Valor:
                                                    R${formatCurrency(state?.plan?.totalAmount)}</h1>
                                                <div style={{display: "flex", alignItems: "flex-start"}}>
                                                    <h2 style={{
                                                        textAlign: "start",
                                                        marginBottom: 25,
                                                        fontSize: 14,
                                                        fontWeight: 700,
                                                        marginRight: 30
                                                    }}>
                                                        Acesse seu APP de pagamentose faça a leitura do QR Code,
                                                        <br/>
                                                        ou copie e cole o código abaixo:
                                                    </h2>
                                                    <span style={{
                                                        position: "relative",
                                                        bottom: -5,
                                                        fontSize: 14,
                                                        fontWeight: 700,
                                                        marginRight: 30,
                                                        color: "#2cd0b1",
                                                        cursor: "pointer"
                                                    }} onClick={() => navigator.clipboard.writeText(success.pixCode)}>Copiar código</span>
                                                </div>
                                                <p style={{maxWidth: 500, wordBreak: "break-all"}}>{success.pixCode}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <>
                                    <div className="left">
                                        <div className="card">
                                            <h1>Forma de pagamento</h1>
                                            <div
                                                className={`radio__container ${paymentMethod === 'CREDIT_CARD' ? 'checked' : ''}`}>
                                                <div>
                                                    <input type="radio" name="paymentMethod"
                                                           checked={paymentMethod === 'CREDIT_CARD'} value="CREDIT_CARD"
                                                           onChange={(e) => setPaymentMethod(e.target.value)}/>
                                                    <label htmlFor="">Cartão de crédito</label>
                                                </div>
                                                <img src={creditCard} alt="creditCardIcon"/>
                                            </div>
                                            <div
                                                className={`radio__container ${paymentMethod === 'PIX' ? 'checked' : ''}`}>
                                                <div>
                                                    <input type="radio" name="paymentMethod"
                                                           checked={paymentMethod === 'PIX'} value="PIX"
                                                           onChange={(e) => setPaymentMethod(e.target.value)}/>
                                                    <label htmlFor="">PIX</label>
                                                </div>
                                                <img src={pix} alt="pixIcon"/>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <h1>Dados pessoais</h1>
                                            <div className="input__container">
                                                <label htmlFor="">Nome</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={personalData.name}
                                                    placeholder="Digite o seu nome"
                                                    style={{borderColor: !personalData.name && touched.name ? '#FF0000' : ''}}
                                                    onBlur={() => setTouched({...touched, name: true})}
                                                    onChange={(e) => setPersonalData({
                                                        ...personalData,
                                                        name: e.target.value
                                                    })}
                                                />
                                            </div>
                                            <div className="input__container">
                                                <label htmlFor="">E-mail</label>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    value={personalData.email}
                                                    onBlur={() => setTouched({...touched, email: true})}
                                                    style={{borderColor: (!regexEmail.test(personalData.email) || !personalData.email) && touched.email ? '#FF0000' : ''}}
                                                    placeholder="Digite um e-mail válido"
                                                    onChange={(e) => setPersonalData({
                                                        ...personalData,
                                                        email: e.target.value
                                                    })}
                                                />
                                            </div>
                                            <div className="input__container">
                                                <label htmlFor="">CPF</label>
                                                <input
                                                    type="text"
                                                    name="cpf"
                                                    style={{borderColor: !validateCPF(personalData.cpf) && touched.cpf ? '#FF0000' : ''}}
                                                    onBlur={() => setTouched({...touched, cpf: true})}
                                                    placeholder="Digite o seu CPF"
                                                    maxLength={14}
                                                    value={personalData.cpf}
                                                    onChange={(e) => setPersonalData({
                                                        ...personalData,
                                                        cpf: mask(e.target.value)
                                                    })}
                                                />
                                            </div>

                                            {paymentMethod === 'PIX' &&
                                                <>
                                                    <div className="input__container">
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                name="terms"
                                                                onChange={() => {
                                                                    setDispatch({...dispatch, terms: !dispatch.terms})
                                                                }}
                                                            />
                                                            <label htmlFor="">Li e concordo com os <span
                                                                onClick={() => window.open('https://quivon.com.br/termo', '_blank')}>termos de uso</span> da
                                                                Quivon
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="input__container">
                                                        <button
                                                            style={
                                                                !dispatch.terms ||
                                                                !personalData.name ||
                                                                !regexEmail.test(personalData.email) ||
                                                                !validateCPF(personalData.cpf) ?
                                                                    {
                                                                        backgroundColor: '#CCC',
                                                                        cursor: "not-allowed"
                                                                    } : {cursor: "pointer"}
                                                            }
                                                            disabled={
                                                                !dispatch.terms ||
                                                                !personalData.name ||
                                                                !regexEmail.test(personalData.email) ||
                                                                !validateCPF(personalData.cpf)
                                                            }
                                                            onClick={() => {
                                                                setDispatch({...dispatch, loading: true})
                                                                getLink().then((response) => {
                                                                    setDispatch({...dispatch, loading: false})
                                                                    setDispatch({...dispatch, success: true})
                                                                    setSuccess(response.data)
                                                                }).catch((error) => {
                                                                    console.log(error)
                                                                    setDispatch({
                                                                        ...dispatch,
                                                                        loading: false,
                                                                        error: true
                                                                    })
                                                                    setError(error.response.data)
                                                                })
                                                            }}
                                                        >
                                                            Gerar QR Code
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                        </div>

                                        {paymentMethod === 'CREDIT_CARD' &&
                                            <div className="card">
                                                <h1>Dados de pagamento</h1>
                                                <div className="input__container">
                                                    <label htmlFor="">Número do cartão</label>
                                                    <input
                                                        type="text"
                                                        name="number_card"
                                                        placeholder="1234 5678 910 1112"
                                                        maxLength={19}
                                                        onKeyPress={onlyNumber}
                                                        value={paymentData.cardNumber}
                                                        style={{borderColor: !validateCard(paymentData.cardNumber.replace(/\s/g, ''), cards) && touched.cardNumber ? '#FF0000' : ''}}
                                                        onBlur={() => setTouched({...touched, cardNumber: true})}
                                                        onChange={(e) => setPaymentData({
                                                            ...paymentData,
                                                            cardNumber: addSpaces(e.target.value)
                                                        })}
                                                    />
                                                </div>
                                                <div className="input__container">
                                                    <label htmlFor="">Nome no cartão</label>
                                                    <input
                                                        type="text"
                                                        name="card_name"
                                                        style={{borderColor: !paymentData.cardName && touched.cardName ? '#FF0000' : ''}}
                                                        placeholder="Digite o nome"
                                                        value={paymentData.cardName}
                                                        onChange={(e) => setPaymentData({
                                                            ...paymentData,
                                                            cardName: e.target.value.toUpperCase()
                                                        })}
                                                        onBlur={() => setTouched({...touched, cardName: true})}
                                                    />
                                                </div>
                                                <div style={{
                                                    flexDirection: "row",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    width: "100%"
                                                }}>
                                                    <div className="input__container" style={{width: "48%"}}>
                                                        <label htmlFor="">Validade</label>
                                                        <input
                                                            type="text"
                                                            name="card_expire"
                                                            style={{borderColor: !paymentData.cardExpiration && touched.cardExpiration ? '#FF0000' : ''}}
                                                            placeholder="MM/AA"
                                                            maxLength={5}
                                                            onKeyPress={onlyNumber}
                                                            value={paymentData.cardExpiration}
                                                            onChange={(e) => setPaymentData({
                                                                ...paymentData,
                                                                cardExpiration: addBar(e.target.value)
                                                            })}
                                                            onBlur={() => setTouched({
                                                                ...touched,
                                                                cardExpiration: true
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="input__container"
                                                         style={{width: "48%", position: "relative"}}>
                                                        <label htmlFor="" style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            position: "absolute",
                                                            top: -5,
                                                            width: "100%",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            Código de segurança
                                                            <img src={helpCircle} alt="help"
                                                                 style={{width: 24, cursor: "pointer"}}
                                                                 onClick={() => setInformation(true)}/>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="card_code"
                                                            style={{
                                                                borderColor: !paymentData.cardSecurityCode && touched.cardSecurityCode ? '#FF0000' : '',
                                                                position: "absolute",
                                                                bottom: 0
                                                            }}
                                                            placeholder="123"
                                                            maxLength={4}
                                                            onKeyPress={onlyNumber}
                                                            value={paymentData.cardSecurityCode}
                                                            onChange={(e) => setPaymentData({
                                                                ...paymentData,
                                                                cardSecurityCode: e.target.value
                                                            })}
                                                            onBlur={() => setTouched({
                                                                ...touched,
                                                                cardSecurityCode: true
                                                            })}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={"input__container"}>
                                                    <label htmlFor="">Parcelamento</label>
                                                    <select onChange={(e) => setInstallment(e.target.value)}>
                                                        {
                                                            state?.plan?.installmentsPlan.map((installmentPlan, index) =>
                                                                <option key={index}
                                                                        value={installmentPlan.installment}>{installmentPlan.installment === 1 ? 'À Vista - Parcela única de' : `${installmentPlan.installment}x de`} R${formatCurrency(installmentPlan.amount)}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>

                                                <div className="input__container">
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            name="terms"
                                                            onChange={() => {
                                                                setDispatch({...dispatch, terms: !dispatch.terms})
                                                            }}
                                                        />
                                                        <label htmlFor="">Li e concordo com os <span onClick={() => window.open('https://quivon.com.br/termo', '_blank')}>termos de uso</span> da Quivon</label>
                                                    </div>
                                                </div>

                                                <div className="input__container">
                                                    <button
                                                        style={
                                                            !dispatch.terms ||
                                                            !personalData.name ||
                                                            !regexEmail.test(personalData.email) ||
                                                            !validateCPF(personalData.cpf) ||
                                                            !validateCard(paymentData.cardNumber.replace(/\s/g, ''), cards) ||
                                                            !paymentData.cardName ||
                                                            !paymentData.cardExpiration ||
                                                            !paymentData.cardSecurityCode ?
                                                                {backgroundColor: '#CCC', cursor: "not-allowed"} : {}
                                                        }
                                                        disabled={
                                                            !dispatch.terms ||
                                                            !personalData.name ||
                                                            !regexEmail.test(personalData.email) ||
                                                            !validateCPF(personalData.cpf) ||
                                                            !validateCard(paymentData.cardNumber.replace(/\s/g, ''), cards) ||
                                                            !paymentData.cardName ||
                                                            !paymentData.cardExpiration ||
                                                            !paymentData.cardSecurityCode ||
                                                            !installment
                                                        }
                                                        onClick={() => {
                                                            setDispatch({...dispatch, loading: true})
                                                            getLink().then(() => {
                                                                setDispatch({...dispatch, loading: false})
                                                                setDispatch({...dispatch, success: true})
                                                                setCardSuccess(true)
                                                            }).catch((error) => {
                                                                console.log(error)
                                                                setDispatch({...dispatch, loading: false, error: true})
                                                                setError(error.response.data)
                                                            })
                                                        }}
                                                    >
                                                        Efetuar Pagamento
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="right">
                                        <div className="card">
                                            <h1>Resumo</h1>
                                            <h2>{state?.plan?.name}</h2>
                                            <h3>R$ {formatCurrency(state?.plan?.amount)}</h3>
                                            <p>Plano {durationToString(state?.plan?.durationInMonths)}</p>
                                            <ul>
                                                <li>Avaliação a cada <strong>{state?.plan?.evaluationTime} dias</strong>
                                                </li>
                                                <li>Tempo máx. de interação
                                                    de <strong>{state?.plan?.answerTime} horas</strong></li>
                                                {
                                                    state?.plan.descriptions.map((description, index) =>
                                                        <li
                                                    key={index}>{description}</li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </>
                }
            </div>
            <Modal
                isOpen
                onRequestClose={() => setInformation(false)}
                style={{
                    content: {
                        visibility: information ? 'visible' : 'hidden',
                        opacity: information ? 1 : 0,
                        transition: information ? 'visibility 0s linear 0s, opacity 300ms' : 'visibility 0s linear 300ms, opacity ',
                        display: 'flex',
                        flexDirection: 'column',
                        borderColor: '#FFFFFF',
                        boxShadow: '0px 10px 20px rgba(43, 37, 63, 0.1)',
                        borderRadius: 12,
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        position: 'relative',
                        padding: "30px 48px",
                    },
                    overlay: {
                        visibility: information ? 'visible' : 'hidden',
                        opacity: information ? 1 : 0,
                        transition: information ? 'visibility 0s linear 0s, opacity 300ms' : 'visibility 0s linear 300ms, opacity ',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 9999,
                    },
                }}
            >
                <div className={"modal__container"}>
                    <img src={iconClose} alt=""/>
                    <h1 style={{textAlign: "center", marginBottom: 20}}>Código de segurança</h1>
                    <img src={CardHelp} alt=""/>
                    <p>
                        O código CVV é um número de segurança de três dígitos (ou quatro, no caso da American Express)
                        encontrado no verso do cartão de crédito ou débito. Ele é usado para validar transações online
                        ou por telefone, protegendo contra uso não autorizado.
                    </p>
                    <button onClick={() => setInformation(false)}>
                        Entendi
                    </button>
                </div>
            </Modal>
        </main>
    )
}

export default Payments