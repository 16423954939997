import React, { useState } from 'react'
import '../global.css'
import ReactPlayer from 'react-player'

import Logo from '../assets/quivon.svg'
import Video from '../assets/quivon.mp4'

import 'animate.css';
import { TextField } from '@mui/material'

function Analise() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    return (

        <div className='container_analise'>
            <div>
                <img className="analise_logo" src={Logo} />
            </div>
            <div className='analise_aside_left animate__animated animate__fadeInLeft'>
                <div style={{ marginTop: 15 }}>
                    <h1>Quer atenção de um profissional?</h1>
                    <h1>Corrija já o seu treino!</h1>
                </div>

                <div style={{ display: 'flex', marginTop: 30, flexDirection: 'column', width: '84%' }}>
                    <div className='input_container_analise animate__animated animate__fadeInLeft'>
                        <TextField
                            sx={{
                                color: 'white',
                            }}
                            fullWidth
                            variant="outlined"
                            label="Nome"
                            onKeyUpCapture={(e) => { setName(e.target.value) }}
                            className='input_analise'
                            required
                        />
                    </div>

                    <div className='input_container_analise animate__animated animate__fadeInLeft'>
                        <TextField
                            variant="outlined"
                            label="Email"
                            type={'email'}
                            fullWidth
                            onKeyUpCapture={(e) => { setEmail(e.target.value) }}
                            className='input_analise'
                            required />
                    </div>
                </div>

                {name.length >= 1 && email.length >= 1 && email.includes('.com') &&
                    <a
                        _blank
                        href={`https://wa.me/5511994205188?text=Olá%20Thi%20Franco%20me%20chamo%20${name}%20gostaria%20de%20ter%20meu%20video%20avaliado%20aqui%20está%20meu%20email:%20${email}`}

                        classname='input_analise_submit'>
                        Consultar
                    </a>}

            </div>

            <div className='container_video_analise'>
                <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url="https://www.youtube.com/shorts/XNnJFEuWgdI"
                    autoplay
                    playing
                    loop
                    controls 
                    
                    />
            </div>
        </div>

    )
}

export default Analise