import React from 'react'
import WomanWorkout from '../assets/woman_lp.jpg'

import Logo from '../assets/quivon.svg'
import iphone1 from "../assets/iphone1.png"
import workout from "../assets/icons/lp-workout.png"
import collaboration from "../assets/icons/lp-collaboration.png"
import graph from "../assets/icons/lp-graphic.png"
import medal from "../assets/icons/lp-medal.png"
import help from "../assets/icons/lp-help.png"
import womanWorkout from '../assets/lp_woman_workout.jpg'
import correct from "../assets/icons/correct-icon.svg"
import ReactPlayer from 'react-player'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMoreIcon';

import tutorial_cupom_0 from "../assets/app_tutorial_0.jpg"
import tutorial_cupom_1 from "../assets/app_tutorial_1.jpg"
import tutorial_cupom_2 from "../assets/app_tutorial_2.jpg"
import tutorial_cupom_3 from "../assets/app_tutorial_3.jpg"
import tutorial_cupom_4 from "../assets/app_tutorial_4.jpg"

import manEvolution from "../assets/team_ulysses_photo_man.jpg"
import manEvolution2 from "../assets/team_ulysses_photo_man2.jpg"
import manEvolution3 from "../assets/team_ulysses_photo_man3.jpg"
import manEvolution4 from "../assets/team_ulysses_photo_man4.jpg"
import manEvolution5 from "../assets/team_ulysses_photo_man5.jpg"
import manEvolution6 from '../assets/team_ulysses_photo_man6.jpg'
import manEvolution7 from "../assets/team_ulysses_photo_man7.jpg"
import manEvolution8 from '../assets/team_ulysses_photo_man8.jpg'
import manEvolution9 from '../assets/team_ulysses_photo_man9.jpg'

import womanEvolution from "../assets/team_ulysses_photo_woman.jpg"
import womanEvolution2 from "../assets/team_ulysses_photo_woman2.jpg"
import womanEvolution3 from "../assets/team_ulysses_photo_woman3.jpg"
import womanEvolution4 from "../assets/team_ulysses_photo_woman4.jpg"
import womanEvolution5 from "../assets/team_ulysses_photo_woman5.jpg"
import womanEvolution6 from "../assets/team_ulysses_photo_woman6.jpg"
import womanEvolution7 from "../assets/team_ulysses_photo_woman7.jpg"
import womanEvolution8 from "../assets/team_ulysses_photo_woman8.jpg"
import { Carousel } from 'react-responsive-carousel'



const quivon = () => {


    const EvolutionPhotos = [
        {
            id: 1,
            photo: womanEvolution,

        },
        {
            id: 2,
            photo: womanEvolution2,
        },
        {
            id: 3,
            photo: womanEvolution3,

        },
        {
            id: 4,
            photo: womanEvolution4,
        },
        {
            id: 5,
            photo: womanEvolution6,

        },
        {
            id: 6,
            photo: womanEvolution7,
        },
        {
            id: 7,
            photo: womanEvolution8,

        },
        {
            id: 8,
            photo: womanEvolution5,
        }
    ]

    const App_features = [
        {
            name: 'Focado no Seu Objetivo',
            icon: medal,
        },
        {
            name: 'Treinamento Personalizado',
            icon: collaboration,
        },
        {
            name: 'Acompanhamento da Performance',
            icon: graph,
        },
        {
            name: 'Resultados rápidos e visíveis',
            icon: workout,
        },
        {
            name: 'Suporte e Correções',
            icon: help,
        }
    ]

    const App_bullets = [
        {
            bullet: 'Fotos e vídeos demonstrativos dos exercícios'
        },
        {
            bullet: 'Acompanhamento dos dias de treino'
        },
        {
            bullet: 'Acompanhamento da intensidade'
        },
        {
            bullet: 'Análise de performance - evolução do treinamento'
        },
        {
            bullet: 'Acesso a suporte quando precisar'
        },
        {
            bullet: 'Intuitivo e fácil de usar'
        },
    ]

    const TutorialSteps = [
        {
            name: 'Baixe o nosso APP',
            image: tutorial_cupom_0,
        },
        {
            name: 'Após realizar o seu cadastro, clique na área do coach no menu de navegação inferior',
            image: tutorial_cupom_1,
        },
        {
            name: 'Utilize o código do seu personal',
            image: tutorial_cupom_2,
        },
        {
            name: 'Após inserir o código confirme o pagamento',
            image: tutorial_cupom_3,
        },
        {
            name: 'Agora você vai conseguir receber o acompanhamento personalizado de forma Online',
            image: tutorial_cupom_4,
        },
    ]

    return (
        <div className='lp_container' style={{ background: '#3770f4' }}>
            <div>
                <img className="analise_logo" src={Logo} />
            </div>
            <div className='lp_section_1' style={{display: 'flex'}}>
                <div className='lp_titles'>
                    <h1>Você tá cansada de treinar, se cansar e não enxergar resultados? Nós temos a solução perfeita para você</h1>
                    <h6>Pare de perder tempo com treinos que não funcionam e só te deixam cansada. Tenha uma consultoria fitness especializada completa e com acompanhamento de Personal Trainer na palma da sua mão, quando e onde quiser. Conheça agora o Quivon App</h6>
                    <button>Quero usar o App</button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 15 }}>
                    <img style={{ height: '25em', borderRadius: 15 }} src={WomanWorkout} />
                </div>
            </div>

            <div className='lp_section_2'>
                <div className='lp_container_lead'>
                    <div className='lp_lead_left'>
                        <h1>O Quivon é feito para as suas necessidades</h1>
                        <p>Seja qual for a sua busca de corpo ideal, nós preparamos um programa totalmente personalizado e perfeito para você alcançar o seu objetivo, com acompanhamento, análise dos treinos e evolução, programas de exercícios e correções, além de suporte quando você precisar</p>
                        <button>Quero usar o App</button>
                    </div>
                    <div className='lp_lead_right'>
                        <img src={iphone1} />
                    </div>
                </div>
            </div>

            <div className='lp_section_3'>
                <div className='lp_card'>
                    <div className='lp_card_container'>
                        <h1>Características do App</h1>
                        <hr />
                        <div className='lp_benefits_card'>
                            {App_features.map((feature) => {
                                return (
                                    <div className='steps_name'>
                                        <img src={feature.icon} />
                                        <h4>{feature.name}</h4>
                                    </div>
                                )
                            })}
                        </div>


                    </div>
                </div>
            </div>

            <div className='lp_carousel_container'>
                <h1 style={{ textAlign: 'center', color: '#fff' }}>Faça como elas e conquiste o corpo que você deseja</h1>
                <Carousel autoPlay infiniteLoop showStatus={false} showThumbs={false}>
                    {EvolutionPhotos.map(evolutionPhoto => {
                        return (
                            <div>
                                <div>
                                    <img src={evolutionPhoto.photo} style={{ width: 300, height: 400 }} />
                                </div>
                            </div>
                        )
                    })}
                </Carousel>
            </div>

            <div className='lp_section_4'>
                <div className='container_section_4'>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', width: '80%' }}>
                        <h1>Treinamento Personalizado na sua Mão</h1>
                        {App_bullets.map((bullet) => {
                            return (
                                <div style={{ display: 'flex' }}>
                                    <img src={correct} style={{ width: 15 }} />
                                    <p style={{ marginTop: 10 }}>{bullet.bullet}</p>
                                </div>
                            )
                        })}
                    </div>

                    <div className='video_section_4'>
                        <ReactPlayer

                            width={'100%'}
                            url="https://youtu.be/_2HjzwLfmQE"
                            autoplay
                            playing
                            loop
                            controls
                            muted
                        />
                    </div>
                </div>
            </div>

            <div className='lp_section_5'>
                <div className='lp_section_5_texts'>
                    <h1>Como funciona?</h1>
                    {TutorialSteps.map((step, index) => {
                        return (
                            <p>Passo {index + 1}: {step.name}</p>
                        )
                    })}
                </div>
            </div>

            <div className='lp_section_6'>
                <div class='lp_section_6_texts'>
                    <h1>7/15 Dias para você experimentar o App, sem custo e sem compromisso</h1>
                    <p>Fazendo o download e cadastro no app agora, você terá 7/15 dias totalmente gratuitos para explorar e conhecer todas as funcionalidades do app. Você não precisa cadastrar forma de pagamento.</p>
                    <p>Não é pegadinha e não tem letra miúda. Acreditamos tanto nos resultados que somos capazes de gerar, que confiamos totalmente na eficiência do Quivon e que você ficará satisfeita</p>
                    <p>Acesse e comece agora a conquistar o corpo que você deseja</p>
                </div>
            </div>

            <div className='lp_faq'>
                <div className='lp_faq_container'>
                    <h1>DÚVIDAS FREQUENTES</h1>
                    <Accordion style={{}}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Quem somos nós?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Como é feita a correção?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography>Como são feito os pagamentos?</Typography>
                        </AccordionSummary>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default quivon
