import React, {Component} from "react";
import Carousel from 'react-elastic-carousel';

import page1 from '../../assets/forpartners/page1.png';
import page2 from '../../assets/forpartners/page2.png';
import page3 from '../../assets/forpartners/page3.png';
import page4 from '../../assets/forpartners/page4.png';
import page5 from '../../assets/forpartners/page5.png';
import page6 from '../../assets/forpartners/page6.png';
import page7 from '../../assets/forpartners/page7.png';
import page8 from '../../assets/forpartners/page8.png';

class CarouselPersonal extends Component {


    render () {
        const { items } = this.state;

        return (
            <Carousel
                breakPoints={this.breakPoints}
                style={{width: "90%"}}
            >
                {items.map(item =>
                    <div key={item.id}>
                        <div className="carousel__item__personal">
                            <div className="carousel__text">
                                <h1>
                                    {item.title}
                                </h1>
                                <p>
                                    {item.text}
                                </p>
                            </div>
                            <img alt="Exemplo" src={item.image} className="personal__img"/>
                        </div>
                    </div>
                )}
            </Carousel>
        )
    }

    state = {
        items: [
            {
                id: 1,
                title: 'Dashboard interativo',
                text: 'Tenha controle sobre a consultoria através de um dashboard interativo com resumo das principais interações.',
                image: page1,
            },
            {
                id: 2,
                title: 'Status dos alunos',
                text: 'Trabalhe com visão 360° de todos os seus alunos. Utilize nossos filtros inteligentes para saber quem está motivado ou desmotivado com a consultoria. Quem não está enviando relatórios, quem está treinando e outras informações.',
                image: page2,
            },
            {
                id: 3,
                title: 'Tela dos alunos',
                text: 'Acompanhe o seu aluno a partir de: histórico de fotos, questionários, ficha de treinamento, performance nos treinos, peso e data. Além desses recursos, crie outros formatos que considere relevantes.',
                image: page3,
            },
            {
                id: 4,
                title: 'Criação da ficha de treinamento',
                text: 'Elabore fichas de treinamentos divididas e categorizadas por grupos musculares e dias de treinos. Contamos com um banco de mais de 600 exercícios gravados por nosso time.',
                image: page4,
            },
            {
                id: 5,
                title: 'Repositório da ficha de treinamento',
                text: 'Com suas fichas criadas e categorizadas, fica muito mais fácil para você se planejar no envio e e no acompanhamento do seu aluno no decorrer da consultoria. Se organize de um modo que a sua consultoria se torne prática, e ao mesmo tempo, customizada.',
                image: page5,
            },
            {
                id: 6,
                title: 'Deadline entrega para alunos',
                text: 'A partir de recursos como inteligente timer, saiba quais são os alunos que precisam de maior atenção nas respostas baseado nas configurações dos seus planos cadastrados. Além disso, confira também os alunos com resposta em atraso.',
                image: page6,
            },
            {
                id: 7,
                title: 'Dados do aluno',
                text: 'Confira as informações dos seus alunos em uma tela de cadastro intuitiva. Além disso, converse com eles via WhatsApp e/ou Telegram em apenas um clique! ',
                image: page7,
            },
            {
                id: 8,
                title: 'Questionário',
                text: 'Elabore questionários customizáveis para uma maior personalização com o aluno. Obtenha recursos de respostas como: descritivas, múltipla escolha, caixa de seleção ou com observação.',
                image: page8,
            },
        ],
    }

    constructor(props) {
        super(props)
        this.breakPoints = [
            { width: 768, itemsToShow: 1 },
            { width: 1080, itemsToShow: 2, itemsToScroll: 2 },
        ]
    }

}

export default CarouselPersonal
