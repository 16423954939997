import React from 'react'
import {useSearchParams} from "react-router-dom";
import EyeIcon from "../assets/icons/eye-line.svg";
import EyeOffIcon from "../assets/icons/eye-off-line.svg";
import QuivonLogo from "../assets/icons/quivon 1.svg";
import axios from "axios";
import {CircularProgress} from "@mui/material";
import Confirmation from "../assets/icons/confirmation.svg";
import Error from "../assets/icons/Error.svg";
import Modal from "react-modal";

const ForgotPassword = () => {

    const [searchParams] = useSearchParams()

    const [newPassword, setNewPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState({
        status: false,
        title: '',
        message: '',
    })

    const [confirmPasswordVisible, setConfirmPasswordVisible] = React.useState(false)
    const [newPasswordVisible, setNewPasswordVisible] = React.useState(false)

    async function getLink(value) {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/app/forgot/password`, {
                "password": value,
                "token": searchParams.get('token')
            },
            {
                headers: {
                    'tenant': 'quivon',
                }
            })

        return response.data;
    }

    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f7f7fa',
            }}
        >
            <img
                style={{
                    width: '200px',
                    marginBottom: '2rem',
                }}
                src={QuivonLogo} alt="QuivonLogo"/>
            <h1
                style={{
                    fontSize: '24px',
                    fontWeight: '700',
                    marginBottom: '2rem',
                    fontStyle: 'normal',
                    color: '#33303e',
                    fontFamily: 'MontSerrat',
                    LineHeight: '31px',
                }}
            >
                Cadastre uma nova senha
            </h1>
            <form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                onSubmit={(e) => {
                    e.preventDefault()
                    setLoading(true)
                    getLink(newPassword).then(() => {
                        setLoading(false)
                        setSuccess(true)
                    }).catch((error) => {
                        setLoading(false)
                        setError({
                            status: true,
                            title: error.response.data.title,
                            message: error.response.data.message,
                        })
                    })
                }}
            >
                <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
                    <label
                        style={{
                            marginBottom: '8px',
                            fontSize: '14px',
                            fontWeight: '700',
                            fontStyle: 'normal',
                            color: '#363543',
                            fontFamily: 'MontSerrat',
                        }}
                        htmlFor="newPassword"
                    >
                        Nova senha
                    </label>
                    <input
                        style={{
                            width: '400px',
                            padding: '16px',
                            borderRadius: '6px',
                            border: '1px solid rgb(220, 221, 227)',
                            backgroundColor: 'transparent',
                            fontSize: '14px',
                            fontWeight: '500',
                            fontStyle: 'normal',
                            color: '#363543',
                            fontFamily: 'MontSerrat',
                        }}
                        name="password"
                        type={newPasswordVisible ? 'text' : 'password'}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Digite Sua Nova Senha"
                    />
                    <img
                        style={{
                            cursor: 'pointer',
                            width: '24px',
                            position: 'absolute',
                            right: '16px',
                            top: '41px',
                        }}
                        src={newPasswordVisible ? EyeIcon : EyeOffIcon}
                        alt=""
                        onClick={() => setNewPasswordVisible(prevState => !prevState)}
                    />

                    {newPassword.length < 6 && newPassword.length > 0 && (
                        <span
                            style={{
                                fontSize: '14px',
                                fontWeight: '400',
                                fontStyle: 'normal',
                                color: '#ff0000',
                                fontFamily: 'MontSerrat',
                                marginTop: '8px',
                            }}
                        >
                            sua senha deve ter mais que 6 dígitos
                        </span>
                    )}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', margin: 24, position: 'relative'}}>
                    <label
                        htmlFor="confirmPassword"
                        style={{
                            marginBottom: '8px',
                            fontSize: '14px',
                            fontWeight: '700',
                            fontStyle: 'normal',
                            color: '#363543',
                            fontFamily: 'MontSerrat',
                        }}
                    >
                        Confirmar nova senha
                    </label>
                    <input
                        style={{
                            width: '400px',
                            padding: '16px',
                            borderRadius: '6px',
                            border: '1px solid rgb(220, 221, 227)',
                            backgroundColor: 'transparent',
                            fontSize: '14px',
                            fontWeight: '500',
                            fontStyle: 'normal',
                            color: '#363543',
                            fontFamily: 'MontSerrat',
                        }}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        name="confirmPassword"
                        type={confirmPasswordVisible ? 'text' : 'password'}
                        placeholder="Confirme Sua Nova Senha"
                    />

                    <img
                        style={{
                            cursor: 'pointer',
                            width: '24px',
                            position: 'absolute',
                            right: '16px',
                            top: '41px',
                        }}
                        src={confirmPasswordVisible ? EyeIcon : EyeOffIcon}
                        alt=""
                        onClick={() => setConfirmPasswordVisible(prevState => !prevState)}
                    />
                    {newPassword !== confirmPassword && confirmPassword.length > 0 && (
                        <span
                            style={{
                                fontSize: '14px',
                                fontWeight: '400',
                                fontStyle: 'normal',
                                color: '#ff0000',
                                fontFamily: 'MontSerrat',
                                marginTop: '8px',
                            }}
                        >
                            As senhas não são iguais
                        </span>
                    )}
                </div>
                {loading ?
                    <CircularProgress style={{color: "#2CD0B1"}}/> :
                    <button
                        type="submit"
                        disabled={
                            newPassword.length < 6 ||
                            newPassword !== confirmPassword ||
                            newPassword.length === 0 ||
                            confirmPassword.length === 0
                        }
                        style={{
                            width: '90%',
                            padding: '16px',
                            borderRadius: '100px',
                            border: 'none',
                            backgroundColor: newPassword.length < 6 ||
                            newPassword !== confirmPassword ||
                            newPassword.length === 0 ||
                            confirmPassword.length === 0 ?
                                '#c4c4c4' :
                                '#2CD0B1',
                            color: '#fff',
                            fontSize: '16px',
                            fontWeight: '600',
                            fontStyle: 'normal',
                            fontFamily: 'MontSerrat',
                            textTransform: 'uppercase',
                            cursor: newPassword.length < 6 ||
                            newPassword !== confirmPassword ||
                            newPassword.length === 0 ||
                            confirmPassword.length === 0 ?
                                'not-allowed' :
                                'pointer',
                        }}
                    >
                        Redefinir Senha
                    </button>

                }
            </form>

            <Modal
                isOpen
                onRequestClose={() => setError(false)}
                style={{
                    content: {
                        visibility: success ? 'visible' : 'hidden',
                        opacity: success ? 1 : 0,
                        transition: success ? 'visibility 0s linear 0s, opacity 300ms' : 'visibility 0s linear 300ms, opacity ',
                        display: 'flex',
                        flexDirection: 'column',
                        borderColor: '#FFFFFF',
                        boxShadow: '0px 10px 20px rgba(43, 37, 63, 0.1)',
                        borderRadius: 12,
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        position: 'relative',
                        padding: "30px 48px",
                    },
                    overlay: {
                        visibility: success ? 'visible' : 'hidden',
                        opacity: success ? 1 : 0,
                        transition: success ? 'visibility 0s linear 0s, opacity 300ms' : 'visibility 0s linear 300ms, opacity ',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 9999,
                    },
                }}
            >
                <div className={"modal__container"}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            height: 400,
                        }}
                    >
                        <h1 style={{textAlign: "center", marginBottom: 20}}>
                            Senha alterada com sucesso!
                        </h1>
                        <img src={Confirmation} style={{width: 220}} alt=""/>
                        <button onClick={() => {
                            if(process.env.REACT_APP_ENV === 'dev'){
                                window.open('https://develop.partners.quivon.com.br', '_self')
                            } else {
                                window.open('http://partners.quivon.com.br', '_self')
                            }
                        }}>
                            Entendi
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen
                onRequestClose={() => setError(false)}
                style={{
                    content: {
                        visibility: error.status ? 'visible' : 'hidden',
                        opacity: error.status ? 1 : 0,
                        transition: error.status ? 'visibility 0s linear 0s, opacity 300ms' : 'visibility 0s linear 300ms, opacity ',
                        display: 'flex',
                        flexDirection: 'column',
                        borderColor: '#FFFFFF',
                        boxShadow: '0px 10px 20px rgba(43, 37, 63, 0.1)',
                        borderRadius: 12,
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        position: 'relative',
                        padding: "30px 48px",
                    },
                    overlay: {
                        visibility: error.status ? 'visible' : 'hidden',
                        opacity: error.status ? 1 : 0,
                        transition: error.status ? 'visibility 0s linear 0s, opacity 300ms' : 'visibility 0s linear 300ms, opacity ',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 9999,
                    },
                }}
            >
                <div className={"modal__container"}>
                    <img src={Error} style={{width: 220, alignSelf: 'center'}} alt=""/>
                        <h1 style={{textAlign: "center", marginBottom: 20}}>
                            {error.title}
                        </h1>
                        <p
                            style={{
                                textAlign: "center",
                                fontFamily: 'Montserrat',
                                fontSize: 16,
                                fontWeight: 500,
                                color: '#4F4F4F',
                                lineHeight: 'normal',

                            }}
                        >
                            {error.message}
                        </p>
                        <button onClick={() => {
                            if(process.env.REACT_APP_ENV === 'dev'){
                                window.open('https://develop.partners.quivon.com.br', '_self')
                            } else {
                                window.open('http://partners.quivon.com.br', '_self')
                            }
                        }}>
                            Entendi
                        </button>
                </div>
            </Modal>
        </div>
    )
}

export default ForgotPassword