import icon1 from '../../assets/icons/iconsHelp/icon1.svg'
import icon2 from '../../assets/icons/iconsHelp/icon2.svg'
import icon3 from '../../assets/icons/iconsHelp/icon3.svg'
import plusIcon from '../../assets/icons/iconsHelp/plus.svg'

export const links_doubts = [
    {
        topic: "Aplicativo QUIVON",
        icon: icon1,
        doubts: [
            {   status: 'active',
                name: "Como vivenciar a experiência QUIVON PARTNER",
                text: "Seja você um Personal Trainer parceiro (PARTNER) ou um usuário praticante de atividade física, o primeiro passo para ter a experiência <a href=''>QUIVON APP</a> é baixar o aplicativo na loja de aplicativos do seu celular.",
                link: "experiencia-quivon",
                steps: [
                    "<b>Se tornando um PARTNER.</b> Após baixar o aplicativo no seu celular, faça o cadastro e avise a equipe do grupo do WhatsApp da Quivon em que você se encontra, caso não esteja em nenhum grupo, clique aqui <a href='https://wa.me/5511951060173?text=Gostaria%20de%20me%20tornar%20um%20partner'>entrar em contato.</a>",
                ],
                videos: {
                    mobile: 'https://www.youtube.com/watch?v=zWYNynWRDEs',
                }
            },
            {   status: 'active',
                name: "Como vivenciar a experiência QUIVON USUÁRIO/CLIENTE",
                link: "experiencia-quivon-usuario-cliente",
                text: "Seja você um Personal Trainer parceiro (PARTNER) ou um usuário praticante de atividade física, o primeiro passo para ter a experiência <a href=''>QUIVON APP</a> é baixar o aplicativo na loja de aplicativos do seu celular.",
                steps: [
                        "<b>Se tornando um usuário/cliente.</b> Você pode se tornar aluno de um <b>PARTNER</b> através de um convite do mesmo, aplicando o código do plano na tela <b>COACH</b> do seu aplicativo ou através do link de confirmação de pagamento recebido em seu e-mail após a compra do plano do <b>PARTNER</b>"],
                videos: {
                    desktop: "https://www.youtube.com/watch?v=KqICy4HH6go",
                    // mobile: "https://www.youtube.com/watch?v=KqICy4HH6go",
                }
            },
            {   status: 'active',
                name: "Primeiro Acesso Aluno / Cliente",
                link: "primeiro-acesso-aluno-cliente",
                text: "Antes de se tornar aluno(a) de um PARTNERS o usuário já consegue adicionar a sua rotina pessoal de atividade física, com dias e horários, a serem compridos como tarefa pessoal, registrar como está sendo a sua dieta, hidratação e sono. Também poderá criar um <a href='/help/criacao-projetos'>Projeto Grátis!</a> individual ou em grupo, além de em seu perfil registrar as fotos da sua evolução. ",
                steps: ['<b>Rotina de atividades (Meus treinos).</b> O usuário gerencia suas atividades adicionando uma rotina de qual atividades pratica ou pretende praticar, com os dias e horários a serem compridos. Isso será computado em uma escala de desempenho de cada atividade para ficar registrada.',
                    '<b>Rotina de atividades (Rotina diária).</b> Ative ou desative os botões de dieta, sono e água, eles aparecerão na tela inicial do seu usuário para serem computados diariamente em uma escala de desempenho de cada um para ficarem registradas.',
                    '<b>Perfil.</b> A evolução do usuário em datas, peso e fotos além de uma observação de cada momento ficará registrada em seu perfil.'],
                videos: {
                    mobile: "https://www.youtube.com/watch?v=E-kLscex2O0",
                }
            },
            {   status: 'active',
                name: "Criação de Projetos",
                link: "criacao-projetos",
                text: "A criação de um projeto pelo usuário é um grande potencializador de motivação desenvolvida pela QUIVON, ela pode ser criada de forma pública ou privada, pode ser individual ou em grupo, com premiação ou sem.",
                steps: ['<b>Projeto Público.</b> O usuário ou partner é capaz de criar um projeto, este projeto pode ser encontrado por outros usuários do aplicativo que para entrarem terão de pedir solicitação para o administrador do grupo.',
                    '<b>Projeto Privado.</b> O usuário ou partner é capaz de criar um projeto que só poderá entrar quem for convidado por um administrador do projeto.'],
                videos: {
                    mobile: "https://www.youtube.com/watch?v=LVm0pMNKhxU",
                }
            },
            {   status: 'active',
                name: "Se tornando aluno / cliente",
                link: "se-tornando-aluno-cliente",
                text: "O usuário do aplicativo se torna aluno/cliente de um PARTNER após efetuar o pagamento do plano criado por este PARTNER, o pagamento pode ser feito através do site deste partner ou através do próprio aplicativo. Entenda as <a href='/help/formas-pagamento'>formas de pagamento</a>. ",
                steps: ['<b>Pagamento na LandingPage.</b> Todo partners da QUIVON possuem uma página para divulgarem seus planos para seus potenciais clientes. É através desta página que o PARTNER possibilita um aluno/cliente a ter acesso ao planejamento criado por ele.',
                    '<b>Pagamento direto no App.</b> O PARTNER ao criar um plano ele pode divulgar esse plano para seus potenciais clientes que já baixaram o aplicativo através de um código do plano que será ativado na tela COACH. Caso esse cliente ainda não tenha baixado o aplicativo pode ser convidado diretamente pelo PARTNER em cadastra'],
                videos: {
                    desktop: "https://www.youtube.com/watch?v=WDu3k50fHrE",
                    mobile: "https://www.youtube.com/watch?v=CrjVRd8NRlI",
                }
            },
            {   status: 'active',
                name: "Realização da compra de um plano de acompanhamento profissional",
                link: "compra-plano-acompanhamento",
                text: "O nosso parceiro (partner) cadastrou um plano em nossa plataforma e esse plano pode ter um período de vigência mensal, trimestral, semestral ou anual que corresponderá ao período de atendimento deste profissional. A compra deste plano pode ser realizada no site deste profissional por PIX ou cartão de crédito ou no aplicativo com cartão de crédito.",
                steps: ['<b>Pagamento pelo site.</b>  O pagamento pelo site do partner faz com que o usuário se conecte com esse profissional que irá fazer a <a href="/help/acesso-forpartners">montagem do planejamento</a> através do seu usuário em https://partners.quivon.com.br/',
                    '<b>Pagamento pelo App.</b>  O pagamento pelo aplicativo do usuário faz com que o mesmo se conecte com o profissional contratado que irá fazer a <a href="/help/acesso-forpartners">montagem do planejamento</a> através do seu usuário em https://partners.quivon.com.br/'],
                videos: {
                    mobile: "https://www.youtube.com/watch?v=cPkNjzHp1kg",
                }
            },
            {   status: 'active',
                name: "Acompanhamento profissional",
                link: "acompanhamento-profissional",
                text: "O acompanhamento do profissional parceiro da QUIVON é ativado após o pagamento de seu plano. O nosso parceiro já haverá feito o <a href='acesso-forpartners'>cadastro de um questionário</a> com informações que ele julga necessárias para montagem do seu planejamento. Esse pedido de resposta do questionário aparecerá em COACH ou na tela início do aplicativo do usuário contratante.",
                steps: ['<b>Preencher Relatório.</b> O relatório será respondido no aplicativo pelo usuário que contratou o acompanhamento profissional e o profissional será notificado em relatórios pendentes para montagem do planejamento.',
                 '<b>Prazo para entrega do planejamento.</b> O profissional contratado ao configurar seu plano ele colocou um prazo máximo para responder ao cliente que está de acordo com esse prazo ao contratar. ',
                 '<b>Reembolso e Pedido de estorno.</b> O pedido de estorno por parte do usuário contratante do acompanhamento profissional é através dos nossos meios de comunicação (<a href="">pedido de estorno</a>).'],
                videos: {
                    mobile: "https://www.youtube.com/watch?v=P4KOS1_o1EQ",
                }
            },


        ]
    },
    {
        topic: "Partner Desktop",
        icon: icon2,
        doubts: [
            {   
                status: 'active',
                name: "Como se tornar um PARTNER (parceiro)",
                link: "como-se-tornar-um-partner",
                text: "Os parceiros da QUIVON são adicionados através de um customer success que usará o mesmo e-mail que o parceiro utilizou em seu cadastro do aplicativo para fazer a ativação dele como um PARTNER. ",
                steps: ['<b>Se tornando um PARTNER.</b> Após baixar o aplicativo no seu celular, faça o cadastro e avise a equipe do grupo do WhatsApp da Quivon em que você se encontra, caso não esteja em nenhum grupo clique aqui <a href="">entrar em contato</a>.',
                         '<b>Acesso DESKTOP.</b> O partner terá seu login e senha em uma versão DESKTOP aonde terá acesso a alunos, questionários, fichas de treino, financeiro, planos e shop em <a src="https://partners.quivon.com.br/">https://partners.quivon.com.br</a>'],
                videos: {
                    mobile: "https://www.youtube.com/watch?v=zWYNynWRDEs&t=43s",
                }
            },
            {   
                name: "Acesso ao FOR PARTNERS",
                iconName: '',
                status: 'active',
                link: "",
                text: "Cada parceiro da QUIVON possui seu usuário dentro do FOR PARTNERS com seu login e senha onde fará a gestão completa de seus clientes. ",
                steps: ['<b><a href="inicio-forpartners">Inicio.</a></b> Um dashboard integrado com seus clientes, com acompanhamento de motivação, relatórios, alunos pendentes, inscrições pendentes e quantidade de alunos em cada plano.',
                    `<b><a href="alunos-forpartners">Alunos</a></b>.    Uma lista completa de alunos com filtro por nome ou e-mail, plano, motivação e interação. Possibilitando ver o perfil de forma individual de cada cliente.`,
                    '<b>Cadastrar    novo aluno.</b> Possibilidade de cadastrar um novo cliente e gerar um link de convite integrado ao plano de seu acompanhamento profissional.',
                    '<b><a href="questionario-forpartners">Questionário.</a></b> Lista de questionários de anamnese criadas e criação de um novo questionário.',
                    '<b>Criar novo questionário.</b> Crie o título do seu questionário e as perguntas que gostaria que seu cliente responda para montagem de seu planejamento. Esse questionário será atrelado ao seu plano.',
                    '<b><a href="fichas-forpartners">Ficha de treino.</a></b> Lista completa de treinos montados e salvos, com filtro por nome, objetivo, treino e nível.',
                    '<b>Criar nova ficha.</b> Uma lista com mais de mil exercícios, separados por gênero (masculino e feminino) com filtro para buscar pelo nome ou músculo dando a possibilidade de prescrever o número de séries, repetições, a técnica utilizada, o descanso necessário e um campo de observação.',
                    '<b>Financeiro.</b> Um deshboard completo para controle financeiro, com filtro por aluno, tipo (growth ou rocket), situação e <a href="/help/formas-pagamento">formas de pagamento</a>.',
                    '<b><a href="tela-plano-forpartners">Planos.</a></b> Acesso aos planos de acompanhamento profissional e códigos de planos.',
                    '<b>Planos de consultoria.</b> Lista por nome, duração, status e vitrine.',
                    '<b>Criar novo plano.</b> Crie um novo plano com nome do plano, vigência, período de avaliação, tempo máximo de resposta e escolha um questionário para esse plano, torne esse plano ativo ou inativo, se ele estará visível na vitrine e o valor a ser cobrado.',
                    '<b><a href="codigo-plano-forpartners">Código dos planos.</a></b> Crie um código pra divulgação de seu plano e controle de desempenho.',
                    '<b>Shop.</b> Acesso a loja com produtos disponíveis ao nosso parceiro, com a possibilidade de personalização.'],
                videos: {
                    desktop: "",
                    mobile: "",
                }
            },
            {
                name: "Tela de inicio",
                status: 'inactive',
                link: "inicio-forpartners",
                text: "",
                steps: ["<b>Inicio.</b> Um dashboard integrado com seus clientes, com acompanhamento de motivação, relatórios, alunos pendentes, inscrições pendentes e quantidade de alunos em cada plano."],
                videos: {
                    desktop: "https://www.youtube.com/watch?v=VwKxrKzO_MY",
                }
            },
            {
                name: "Alunos",
                status: 'inactive',
                link: "alunos-forpartners",
                text: "",
                steps: ["<b>Alunos</b>.    Uma lista completa de alunos com filtro por nome ou e-mail, plano, motivação e interação. Possibilitando ver o perfil de forma individual de cada cliente.",
                        ],
                videos: {
                    desktop: "https://www.youtube.com/watch?v=LHuksgOUD5c",
                }
            },
            {
                name: "Planos",
                status: 'inactive',
                link: "planos-forpartners",
                text: "",
                steps: ["<b>Cadastrar novo aluno.</b> Possibilidade de cadastrar um novo cliente e gerar um link de convite integrado ao plano de seu acompanhamento profissional."],
                videos: {
                    desktop: "",
                    mobile: "",
                }
            },
            {
                name: "Fichas",
                status: 'inactive',
                link: "fichas-forpartners",
                text: "",
                steps: ["<b>Ficha de treino.</b> Lista completa de treinos montados e salvos, com filtro por nome, objetivo, treino e nível."],
                videos: {
                    desktop: "https://www.youtube.com/watch?v=17ZaoK1FWpI",
                }
            },
            {
                name: "Questionário",
                status: 'inactive',
                link: "questionario-forpartners",
                text: "",
                steps: ["<b>Questionário.</b> Lista de questionários de anamnese criadas e criação de um novo questionário."],
                videos: {
                    desktop: "https://www.youtube.com/watch?v=TQajiIjdDv8",
                }
            },
            {
                name: "Tela Plano",
                status: 'inactive',
                link: "tela-plano-forpartners",
                text: "",
                steps: ["<b>Criar novo plano.</b>Crie um novo plano com nome do plano, vigência, período de avaliação, tempo máximo de resposta e escolha um questionário para esse plano, torne esse plano ativo ou inativo, se ele estará visível na vitrine e o valor a ser cobrado."],
                videos: {
                    desktop: "https://www.youtube.com/watch?v=2tixjYN4N5k",
                }
            },
            {
                name: "Codigo do Plano",
                status: 'inactive',
                link: "codigo-plano-forpartners",
                text: "",
                steps: ["<b>Código dos planos.</b> Crie um código pra divulgação de seu plano e controle de desempenho."],
                videos: {
                    desktop: "https://www.youtube.com/watch?v=cmBDtJ_y7KA",
                }
            },
            {   
                name: "Como ter um acompanhamento Profissional",
                status: 'active',
                link: "ativando-acompanhamento",
                text: "A ativação do acompanhamento profissional é feita através do pagamento do plano do profissional, que pode ser feita através da tela COACH do aplicativo inserindo o código do plano que o PARTNER forneceu para o usuário ou clicando no link que o partner forneceu após cadastrar aluno, outra forma é fazendo a compra através da página que a QUIVON fornece para o partners.",
                steps: ['<b>Código do Plano.</b> O código do plano é criado pelo PARTNER dentro da sessão PLANOS. O cliente na tela COACH do aplicativo utiliza esse código para ter acesso ao acompanhamento profissional. ',
                        '<b>Link de acesso.</b> O link de acesso é criado pelo PARTNER na sessão ALUNOS. Ao receber um convite do partner o usuário ao clicar é direcionado a loja de aplicativos do seu celular ou se já tiver baixado o aplicativo ele irá aceitar se tornar aluno/cliente do partner e ter acesso a forma de pagamento de seu plano.',
                        '<b>Contratando através do site.</b> Todo parceiro da QUIVON recebe uma landing page que possibilita de realizar a venda de seus planos. '],
                videos: {
                    desktop: "https://www.youtube.com/watch?v=cQ7342ZhB44"
                }
            },
        ],
    },
    {
        topic: "Pagamentos",
        icon: icon3,
        doubts: [
            {
                name: "Formas de pagamento",
                status: 'active',
                link: "formas-pagamento",
                text: "O cliente tem duas formas de contratar o acompanhamento profissional do nosso PARTNER, através de um link de pagamento ou diretamente no aplicativo na tela COACH.",
                steps: ["<b>Link de pagamento.</b> O link de pagamento pode ser encontrado na landing page do PARTNER ou ele pode mandar diretamente para o cliente.",
                        "<b>Contratando pelo App.</b> O cliente/aluno pode ter passado por um período de experiencia grátis do PARTNER e logo em seguida terá acesso aos planos desse profissional para contratá-lo ou recebendo um código de um plano que mostrará na tela coach a aplicação do código e acesso a forma de pagamento."],
                videos: {
                    desktop: "https://www.youtube.com/watch?v=WDu3k50fHrE",
                    mobile: "https://www.youtube.com/watch?v=CrjVRd8NRlI&t=1s",
                }
            },
            {
                name: "Pedido de estorno",
                status: 'active',
                link: "pedido-estorno",
                text: "O cliente/usuário tem um canal aberto de comunicação com a QUIVON, o pedido de estorno é feito através do nosso botão <a href='https://wa.me/5511951060173?text=Gostaria%20de%20fazer%20um%20estorno%20da%20minha%20compra'>fale conosco</a> em configurações do aplicativo.",
                steps: ["<b>Realizando o estorno.</b> Caso o usuário/cliente do PARNTER queira por qualquer motivo fazer um pedido de estorno, fazemos questão de entender o caso.",
                        "<b>Prazo.</b> O pedido é realizado no mesmo dia e tem estornado já na mesma fatura ou na seguinte. "],
                videos: {
                    desktop: "",
                }
            },
        ]
    }
];
