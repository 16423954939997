import React from 'react'

import '../global.css'

import Logo from '../assets/quivon.svg'

import apple from "../assets/icons/apple-logo.svg"
import google from "../assets/icons/Google__G__Logo.svg"

import ulyssesPhoto from "../assets/ulysses-photo.png"

const ulysses_code = () => {
    return (
        <div className='container_tifranco'>
            <div>
                <img className="analise_logo" src={Logo} />
            </div>
            <div className='containers_ulysses' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <div className='steps animate__animated animate__fadeInLeft animate__delay-2s'>
                        <h1>1º Passo:</h1>
                        <h2>Faça download do <a href='https://www.quivon.com.br/#download__app'>APP</a></h2>
                        <div className="links__download_tifranco" style={{ justifyContent: 'center' }}>
                            <div>
                                <button className="link__download">
                                    <a href="https://apps.apple.com/br/app/motivapp/id1507700739">
                                        <img alt="Apple" width={30} src={apple} />
                                        Disponível na
                                        <br />
                                        App Store
                                    </a>
                                </button>
                            </div>
                            <div>
                                <button className="link__download">
                                    <a href="https://play.google.com/store/apps/details?id=br.com.motivapp.motiva&hl=pt">
                                        <img alt="Google" width={30} src={google} style={{ marginTop: 5 }} />
                                        Disponível na
                                        <br />
                                        Google Play
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='steps animate__animated animate__fadeInLeft animate__delay-4s'>
                        <h1>2º Passo:</h1>
                        <h2>Faça o cadastro</h2>
                    </div>
                    <div className='steps animate__animated animate__fadeInLeft animate__delay-5s'>
                        <h1>3º Passo:</h1>
                        <h2>Clique na área do coach e insira o código!</h2>
                        <h6>FXOWF9P</h6>
                    </div>
                </div>

                <div>
                    <img className='animate__animated animate__fadeIn animate__delay-5s personal_photo' src={ulyssesPhoto} style={{borderRadius: 15, alignSelf: 'end'}} />
                </div>
            </div>
        </div>
    )
}

export default ulysses_code
