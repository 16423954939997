import React, {Component} from "react";

import './Footer.css';

import quivonLogo from '../../assets/quivon.svg'
import whatsapp from '../../assets/icons/whatsapp.svg'
import facebook from '../../assets/icons/facebook.svg'
import twitter from '../../assets/icons/twitter.svg'
import instagram from '../../assets/icons/instagram.svg'
import tiktok from '../../assets/TikTok-Icon-Logo.wine.svg'
import linkedin from '../../assets/linkedin-icon-1.svg'
import spotify from '../../assets/Spotify-Icon-Black-Logo.wine.svg'
import front from '../../assets/icons/front.svg'

class CarouselAluno extends Component {
    render() {
        return (

            <main className="footer">
                <div className="footer__whatsapp">
                    <img alt="Logo Quivon" src={quivonLogo}/>
                    <div className="call__whatsapp">
                        <h2>
                            Possui alguma dúvida?
                        </h2>
                        <a href="https://api.whatsapp.com/send?phone=5511951060173&text=Ol%C3%A1%2C%20gostaria%20de%20um%20suporte%20da%20Quivon.">
                            <button>
                                <img alt="Whatsapp" src={whatsapp}/>
                                Chame no whatsapp
                            </button>
                        </a>
                    </div>
                </div>
                <hr/>
                <div className="footer__email">
                    <div className="send__email">
                        <h2>
                            Receba nossas
                            <br/>
                            novidades
                        </h2>
                        <div id="float-label">
                            <input type="email"/>

                            <label htmlFor="email">
                                Insira seu email
                            </label>
                            <div>
                                <button>
                                    <img alt="Seta adiante" src={front}/>
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="footer__links">
                        <a href="#functionalities">
                            Funcionalidades
                        </a>
                        <a href="https://calendly.com/comercialquivon/30min?month=2021-10">
                            Agende uma conversa
                        </a>
                        <a href="#benefits">
                            Benefícios
                        </a>
                    </div>
                    <div className="social__medias">
                        <div className={"social__medias__links"}>
                            <a href="https://www.facebook.com/quivon">
                                <img alt="facebook" src={facebook} width={15}/>
                            </a>
                            <a href="https://www.twitter.com/quivonapp">
                                <img alt="twitter" src={twitter} width={30}/>
                            </a>
                            <a href="https://www.tiktok.com/@quivon.app">
                                <img alt="tiktok" width={57} src={tiktok}/>
                            </a>
                            <a href="https://www.instagram.com/quivon.app/">
                                <img alt="instagram" src={instagram} width={30}/>
                            </a>
                            <a href="https://www.linkedin.com/company/quivon/">
                                <img alt="linkedin" src={linkedin} width={25}/>
                            </a>
                            <a href="https://open.spotify.com/user/wkn6mjtt9bwf20ib4l2l170cz?si=33f457856d484b6a">
                                <img alt="spotify" src={spotify} width={55}/>
                            </a>
                        </div>

                        <div className={"informations"}>
                            <p>QUIVON TECNOLOGIA DA INFORMACAO LTDA</p>
                            <p>Endereço: Rua Bandeira Paulista, 662, Cj 96. CEP: 04.532-002</p>
                            <p>CNPJ: 39.479.921/0001-14</p>
                        </div>

                    </div>

                </div>

            </main>
        )
    }
}

export default CarouselAluno
