import React, {useEffect, useState} from 'react';
import backgroundTopImage
    from "../white-label-assets/fit-young-man-working-out-in-front-of-gym-friends-2021-08-27-09-54-25-utc 1.png";

import backgroundMidImage from "../white-label-assets/man-fitness-2021-08-29-16-41-29-utc 1.png";
import background2MidImage
    from "../white-label-assets/girl-shakes-the-abs-with-a-medicine-ball-girl-wor-2022-01-10-21-17-16-utc 1.png";
import Arrow from "../white-label-assets/Arrow 3.svg";

import logo from '../white-label-assets/logo.svg';
import IconPremium from '../white-label-assets/IconPremium.svg';
import GifFicha from '../white-label-assets/Gif2-Finalizado Ficha.gif';
import GifQuest from '../white-label-assets/Gif1-Finalizado Quest.gif';
import GifConta from '../white-label-assets/Gif3-Finalizado Contab.gif';
import {useNavigate, useParams} from "react-router-dom";
import Carousel from 'react-elastic-carousel';

import "../white-label-assets/css/style.scss";
import axios from "axios";
import styled from "styled-components";

const StyledCaroussel = styled(Carousel)`
  .rec-arrow {
    background-color: ${props => props.color};
  }

  .rec-dot_active {
    background-color: ${props => props.color};
    box-shadow: 0 0 1px 3px ${props => props.color};

    :hover {
      box-shadow: 0 0 1px 3px ${props => props.color};
    }
  }
`

const WhiteLabel = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const dataCards = [
        {
            "id": 0,
            "title": "Entender o seu nível",
            "description": "Após a confirmação da compra, você receberá um e-mail com o acesso ao aplicativo. Na tela Coach, responda ao questionário customizado para que eu entenda melhor sobre você. Além disso, analisarei seu corpo atual, peso e objetivos.",
            "image": GifQuest
        },
        {
            "id": 1,
            "title": "Prescrição do treinamento",
            "description": "Analisarei suas informações e montarei um treino de acordo com as suas necessidades. Você receberá esse treino em forma de vídeo dentro do aplicativo, tudo muito fácil e ágil.",
            "image": GifFicha
        },
        {
            "id": 2,
            "title": "Progressão de intensidade",
            "description": "Através do app, consigo ter informações detalhadas no decorrer do seu programa (se você treinou, como foi, pesos e etc), entendendo e analisando a sua evolução nos treinos. Você também pode acompanhar a sua evolução de cargas e intensidade através da nossa inteligência artificial.",
            "image": GifConta
        }
    ]

    const [personalData, setPersonalData] = useState({});
    const [cardId, setCardId] = useState(0);

    const [image, setImage] = useState("");

    async function getPlans(id) {
        const response = await axios.get(`https://api.quivon.com.br/v2/partners/${id}/plans`, {
            headers: {
                'tenant': 'quivon',
            }
        })

        return response.data;
    }

    async function getPersonal(id) {
        const response = await axios.get(`https://api.quivon.com.br/v2/admin/landing-page/pages/${id}`, {
            headers: {
                'tenant': 'quivon',
            }
        })

        return response.data;
    }

    const formatCurrency = (value) => {
        return Intl.NumberFormat('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value * 0.01)
    }

    function durationToString(duration) {
        switch (duration) {
            case 1:
                return 'Mensal'
            case 3:
                return 'Trimestral'
            case 6:
                return 'Semestral'
            case 12:
                return 'Anual'
            default:
                return `${duration} meses`
        }
    }

    useEffect(() => {
        dataCards.filter((item) => {
            if (item.id === cardId) {
                setImage(item.image);
            }
        })
    }, [cardId])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCardId(prevState => (prevState + 1) % (dataCards.length));
        }, 15000)

        return () => clearTimeout(timeout);
    }, [cardId, dataCards.length])

    if (personalData?.backgroundColor) {
        document.body.style.backgroundColor = personalData.generalBackground;
    }

    useEffect(() => {
        getPersonal(id).then((response) => {
            setPersonalData(response.data[0])
            if (response.data[0]?.partnerId) {
                getPlans(response.data[0]?.partnerId).then((response) => {
                    setPersonalData(prevState => ({
                        ...prevState, plans: response.data.sort((a, b) => a.amount - b.amount)
                    }))
                })
            }
        })
    }, [id])

    return (
        personalData.title ?
            <main>
                <div style={{backgroundColor: personalData.backgroundColor}} className="background__top">
                    <img src={backgroundTopImage} style={{objectFit: 'cover'}} alt="backgroundTopImage"/>

                    <div className={"right"}>
                        <div className={"rectangle"} style={{backgroundColor: personalData.backgroundColor}}/>
                        <div className={"rectangle__white__tall"}
                             style={{backgroundColor: personalData.squareColor ? personalData.squareColor : personalData.fontColor}}/>
                        <div className={"rectangle__white__tiny"}
                             style={{backgroundColor: personalData.squareColor ? personalData.squareColor : personalData.fontColor}}/>
                        <div className={"rectangle__white__border"}
                             style={{borderColor: personalData.squareColor ? personalData.squareColor : personalData.fontColor}}/>

                        <img src={personalData.image} className={"personal__image"} alt={"personal__image"}/>
                    </div>

                    <div className={"left"}>
                        <img src={logo} alt="logo" className={"logo"}/>

                        <h1 className={"text"} style={{color: personalData.fontColor, fontSize: '3em'}}>
                            {personalData.title}
                        </h1>

                        <h2 style={{color: personalData.fontColor}}>
                            {personalData.name}
                        </h2>

                        <a href="#plans">
                            <button className={"button__green"} style={{background: personalData.buttonColor}}>
                                <p style={{color: personalData?.btnColorText && personalData.btnColorText}}>{personalData.btnText ? personalData.btnText : "Quero entrar para o time"}</p>
                            </button>
                        </a>
                    </div>
                </div>

                <div className={"receive"}>
                    <h1 className={"text"} style={{color: personalData.backgroundColor}} id={'receive'}>
                        Seja Acompanhado de forma online pelo APP:
                    </h1>


                    <div className={"content__receive"}>
                        <img
                            src={image}
                            width="300px"
                            height="600px"
                            id={"gif"}
                        />

                        <div className={"descriptions"}>
                            {!personalData.dataCardsPersonal ? dataCards.map((item) => {

                                    return (
                                        <a
                                            className={"cards"}
                                            style={{
                                                backgroundColor: item.id === cardId ? personalData.backgroundColor : personalData.generalBackground ? personalData.generalBackground : "#fff",
                                                cursor: 'pointer'
                                            }}

                                            href={window.innerWidth < 1300 ? "#gif" : '#receive'}
                                            onClick={() => setCardId(item.id)}
                                        >
                                            <h1 className={"text"} style={{
                                                color: item.id === cardId ? personalData.backgroundColor : personalData.generalBackground ? personalData.generalBackground : "#fff",
                                                textShadow: item.id === cardId ? '2px 2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, -2px -2px 0 #fff, 2px 0px 0 #fff, 0px 2px 0 #fff, -2px 0px 0 #fff, 0px -2px 0 #fff' : '2px 2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, -2px -2px 0 #000, 2px 0px 0 #000, 0px 2px 0 #000, -2px 0px 0 #000, 0px -2px 0 #000'
                                            }}>
                                                0{item.id + 1}
                                            </h1>
                                            <div className={"content__cards"}>
                                                <h2 style={{color: item.id === cardId ? personalData.fontColor : '#171717'}}>{item.title}</h2>
                                                <p style={{color: item.id === cardId ? personalData.fontColor : '#171717'}}>{item.description}</p>
                                            </div>
                                        </a>
                                    )
                                }) :
                                personalData.dataCardsPersonal.map((item) => {
                                        return (
                                            <a
                                                className={"cards"}
                                                style={{
                                                    backgroundColor: item.id === cardId ? personalData.backgroundColor : 'white',
                                                    cursor: 'pointer'
                                                }}

                                                href={window.innerWidth < 1300 ? "#gif" : '#receive'}
                                                onClick={() => setCardId(item.id)}
                                            >
                                                <h1 className={"text"} style={{
                                                    color: item.id === cardId ? personalData.backgroundColor : 'white',
                                                    textShadow: item.id === cardId ? '2px 2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, -2px -2px 0 #fff, 2px 0px 0 #fff, 0px 2px 0 #fff, -2px 0px 0 #fff, 0px -2px 0 #fff' : '2px 2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, -2px -2px 0 #000, 2px 0px 0 #000, 0px 2px 0 #000, -2px 0px 0 #000, 0px -2px 0 #000'
                                                }}>
                                                    0{item.id + 1}
                                                </h1>
                                                <div className={"content__cards"}>
                                                    <h2 style={{color: item.id === cardId ? personalData.fontColor : '#171717'}}>{item.title}</h2>
                                                    <p style={{color: item.id === cardId ? personalData.fontColor : '#171717'}}>{item.description}</p>
                                                </div>
                                            </a>
                                        )
                                    }
                                )}

                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                {dataCards.map((item) => {
                                    return (
                                        <div className={'dots'}
                                             style={{backgroundColor: item.id === cardId ? personalData.backgroundColor : '#dadada'}}/>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </div>

                <div style={{backgroundColor: personalData.backgroundColor}} className={"background__mid"}>
                    <img src={backgroundMidImage} alt="backgroundTopImage" style={{objectFit: 'cover'}}/>

                    <div className={"container__content__mid"} style={{display: "flex", justifyContent: 'center'}}>

                        <div className={"content__mid"}>
                            <h1 className={"text"}
                                style={{
                                    color: personalData.fontColor,
                                    fontSize: 40
                                }}>{personalData?.nichePersonal ? personalData.nichePersonal : 'NÃO TRANSFORMO APENAS CORPOS E SIM A SUA MENTALIDADE'}</h1>
                            <p style={{color: personalData.fontColor}}>
                                {personalData?.nicheText ? personalData.nicheText : 'Com uma das maiores REFERÊNCIAS na área de Educação Física. CONFIRA AGORA AS TRANSFORMAÇÕES JÁ FEITAS'}
                            </p>
                            <a href="#plans" style={{cursor: 'pointer'}}>
                                <button style={{cursor: 'pointer', background: personalData.buttonColor}}
                                        className={"button__green"}>
                                    <p style={{color: personalData?.btnColorText && personalData.btnColorText}}>{personalData.btnText ? personalData.btnText : "Quero entrar para o time"}</p>
                                </button>
                            </a>
                        </div>

                    </div>
                </div>

                <div className={"results"}
                     style={{display: !personalData?.results || personalData?.results?.length === 0 ? 'none' : ''}}>
                    <div className={"left"}>
                        <div className={"content__results"} style={{color: personalData.backgroundColor}}>
                            <h1>{personalData?.sucessClients ? personalData?.sucessClients : 'Clientes Satisfeitos'}</h1>
                            <p>
                                {personalData?.textSucessClients ? personalData.textSucessClients : 'Comece agora e faça parte desse quadro de evoluções. Afinal, se fiz com eles porque não posso fazer com você?'}
                            </p>

                            <div className={"arrow"}>
                                <p>{personalData?.textCarousel ? personalData.textCarousel : "Faça como eles!"}</p>
                                <img src={Arrow} alt="Arrow"/>
                            </div>
                        </div>
                    </div>
                    <div className={"right"}>
                        <StyledCaroussel
                            breakPoints={[]}
                            color={personalData.buttonColor}
                        >
                            {personalData.results?.map((item) => {
                                return <img src={item} alt="result"/>
                            })}
                        </StyledCaroussel>
                    </div>
                </div>

                <div className={"about__me"}>

                    <div className={"left"}>
                        <div className={"content"}>
                            <h1 style={{color: personalData.backgroundColor}}>
                                {personalData?.knowMoreAboutMe ? personalData?.knowMoreAboutMe : 'Conheça mais sobre!'}
                            </h1>
                            <p style={{textAlign: "justify"}}>
                                {personalData.aboutMe?.text && personalData.aboutMe.text}
                            </p>
                        </div>

                        <div className={"about__me__results"}
                             style={{visibility: personalData.personalAchievements ? "visible" : "hidden"}}>
                            <div className={"titles"}>
                                <h1>{personalData.personalAchievements?.achievementsCoach && personalData.personalAchievements?.achievementsCoach}</h1>
                                <p>{personalData.personalAchievements?.achivementsText && personalData.personalAchievements?.achivementsText}</p>
                            </div>
                            <div className={"clients"}>
                                <h1>{personalData.personalAchievements?.clientsSatisfied && personalData.personalAchievements?.clientsSatisfied}</h1>
                                <p>{personalData.personalAchievements?.satisfiedText && personalData.personalAchievements?.satisfiedText}</p>
                            </div>
                        </div>
                    </div>
                    <div className={"right"}>
                        <img src={personalData.aboutMe?.image && personalData.aboutMe.image} alt="Ulis"/>
                    </div>
                </div>

                <div style={{backgroundColor: personalData.backgroundColor}} className={"background__mid"}>
                    <img src={background2MidImage} alt="backgroundTopImage" style={{objectFit: 'cover'}}/>

                    <div className={"container__content__mid"} style={{justifyContent: 'center'}}>
                        <div className={"content__mid"} style={document.body.offsetWidth < 1300 ? {width: 'auto'} : {}}>
                            <h1 className={"text"} style={{color: personalData.fontColor, fontSize: '3em'}}>
                                {personalData?.promisePersonal ? personalData.promisePersonal : 'NÃO TRANSFORMO APENAS CORPOS E SIM A SUA MENTALIDADE.'}
                            </h1>
                            <p style={{color: personalData.fontColor}}>
                                {personalData?.promiseText ? personalData.promiseText : 'Aplique agora mesmo o método de treinamento que mantém você com comprometimento com seus resultados'}
                            </p>
                            <a href="#plans">
                                <button className={"button__green"} style={{background: personalData.buttonColor}}>
                                    <p style={{color: personalData?.btnColorText && personalData.btnColorText}}>{personalData.btnText ? personalData.btnText : "Quero entrar para o time"}</p>
                                </button>
                            </a>
                        </div>

                    </div>
                </div>

                <div className={"training__plan"} id={"plans"}>
                    <div className={"left"}>
                        <h1 className={"text"} style={{color: personalData.backgroundColor}}>
                            {personalData?.planTitle ? personalData?.planTitle : "Planos de treino"}
                        </h1>
                        <p>{personalData?.planText ? personalData?.planText : "Faça parte do meu time e tenha um acompanhamento profissional e personalizado."}</p>
                        <div>
                            <span>Junte-se ao time</span>
                            <img src={Arrow} alt="Arrow"/>
                        </div>
                    </div>
                    <div className={"right"} style={{backgroundColor: personalData.backgroundColor}}>
                        <StyledCaroussel color={personalData.buttonColor}>
                            {personalData.plans && personalData.plans.length > 0 ? (
                                personalData.plans.map((plan) => (
                                    <div className="card__training__plan" key={plan.id}>
                                        <div className="title__card__training__plan">
                                            <div>
                                                <img src={IconPremium} alt="IconPremium"/>
                                                <h3>{plan.name}</h3>
                                            </div>
                                            <h3>Treino personalizado para você</h3>
                                        </div>
                                        <div className="amount__card__training__plan">
                                            <h1>R$ {formatCurrency(plan.amount)}</h1>
                                            <h3>/por mês</h3>
                                        </div>
                                        <div className="amount__card__training__plan">
                                            <strong>Plano {durationToString(plan.durationInMonths)}</strong>
                                        </div>

                                        <button
                                            onClick={() =>
                                                navigate(`/payments`, {state: {plan}})
                                            }
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: personalData.buttonColor,
                                            }}
                                            className="button__green"
                                        >
                                            <p style={{color: personalData.btnColorText ? personalData.btnColorText : "#fff"}}> {personalData.btnText ? personalData.btnText : "Quero entrar para o time"} </p>
                                        </button>

                                        <ul>
                                            <li>
                                                Respostas em até {plan.answerTime} horas
                                            </li>
                                            <li>
                                                Avaliação a cada {plan.evaluationTime} dias
                                            </li>
                                            {
                                                plan.descriptions.map((description) =>
                                                    <li key={description.id}>
                                                        {description}
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                ))
                            ) : (
                                <div className="card__training__plan">
                                    <div className="title__card__training__plan">
                                        <div>
                                            <img src={IconPremium} alt="IconPremium"/>
                                            <h3>Nome do Plano</h3>
                                        </div>
                                        <h3>Crie seu primeiro plano em nosso site</h3>
                                    </div>
                                    <div className="amount__card__training__plan">
                                        <h1>R$ 00,00</h1>
                                        <h3>/por mês</h3>
                                    </div>

                                    <button
                                        style={{
                                            cursor: 'not-allowed',
                                            backgroundColor: 'gray',
                                        }}
                                        className="button__green"
                                        disabled={true}
                                    >
                                        <p style={{color: personalData?.btnColorText && personalData.btnColorText}}>{personalData.btnText ? personalData.btnText : "Quero entrar para o time"}</p>
                                    </button>

                                    <ul>
                                        <li>Todos os benefícios do seu plano serão aqui</li>
                                        <li>Todos os benefícios do seu plano serão aqui</li>
                                        <li>Todos os benefícios do seu plano serão aqui</li>
                                        <li>Todos os benefícios do seu plano serão aqui</li>
                                    </ul>
                                </div>
                            )}

                        </StyledCaroussel>
                    </div>
                </div>
                <footer style={{backgroundColor: personalData.backgroundColor}}>
                    Este produto é de responsabilidade única e exclusiva
                    de {' '} {personalData.footerName ? personalData.footerName : personalData.name} {' '} e a QUIVON
                    não se
                    responsabiliza pelo produto ou serviço prestado.
                    <br/>
                    Em caso de dúvida entre em contato com seu fornecedor.
                </footer>
            </main>
            : null
    )
}

export default WhiteLabel;
