import {Component, useEffect} from "react";
import { Navbar } from "react-bootstrap";
import Main from "./Main";

const DisableRotation = () => {
  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.orientation !== undefined) {
        const orientation = window.orientation;

        // Bloquear a rotação se a orientação for diferente de retrato (portrait)
        if (orientation !== 0) {
          if (window.screen && window.screen.orientation && window.screen.orientation.lock) {
            window.screen.orientation.lock('portrait').catch((error) => {
              console.error('Falha ao bloquear a orientação de tela:', error);
            });
          }
        }
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange, false);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return null;
};


class App extends Component {

    render() {
        return (
            <div className="App">
                <Navbar />
                <Main />
                <DisableRotation />
            </div>
        )
    }
}

export default App;