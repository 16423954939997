import React, {Component} from "react";
import Carousel from 'react-elastic-carousel';
import "./Carousel.css";

import iphone1 from '../../assets/forstudents/iphone_aluno1.png';
import iphone2 from '../../assets/forstudents/iphone_aluno2.png';
import iphone3 from '../../assets/forstudents/iphone_aluno3.png';
import iphone4 from '../../assets/forstudents/iphone_aluno4.png';
import iphone5 from '../../assets/forstudents/iphone_aluno5.png';
import iphone6 from '../../assets/forstudents/iphone_aluno6.png';
import iphone7 from '../../assets/forstudents/iphone_aluno7.png';
import iphone8 from '../../assets/forstudents/iphone_aluno8.png';

class CarouselAluno extends Component {

    render () {
        const { items } = this.state;

        return (
            <Carousel
                breakPoints={this.breakPoints}
                style={{width: "90%"}}
                className="carousel__main"
            >
                {items.map(item =>
                    <div>
                    <div className="carousel">
                        <div className="carousel__item">
                            <div className="carousel__text">
                                <h1>
                                    {item.title}
                                </h1>
                                <p>
                                    {item.text}
                                </p>
                            </div>
                            <img alt="Exemplo" src={item.image} className="student__img"/>
                        </div>
                    </div>
                    </div>
                )}
            </Carousel>
        )
    }

    state = {
        items: [
            {
                id: 1,
                title: 'Criação de projetos',
                text: 'Desafie-se e seja motivador através de uma data específica ou algo que seja importante para você. Tenha todas essas datas e projetos na sua tela, se auto motivando em diferentes eventos.\n',
                image: iphone1,
            },
            {
                id: 2,
                title: 'Acompanhamento de projetos',
                text: 'Dia após dia, verifique se a sua meta no compromisso almejado está sendo cumprida. Esteja por dentro de sua performance através de gráficos e dados interativos.\n',
                image: iphone2,
            },
            {
                id: 3,
                title: 'Histórico de performance',
                text: 'Acompanhe o seu histórico de performance. Nada será mais gratificante do que ver, mensalmente, o verdinho na tela de treinos retroativos.\n',
                image: iphone3,
            },
            {
                id: 4,
                title: 'Acompanhamento de evolução',
                text: 'Acompanhe sua evolução adicionando fotos, data e peso em sua timeline. Com isso, fica mais claro você ver a mudança, fazendo com que você se motive cada vez mais.\n',
                image: iphone4,
            },
            {
                id: 5,
                title: 'Ficha de treinamento',
                text: 'Realize uma consultoria online com seu personal de confiança. Receba na sua timeline treinos exclusivos para você.',
                image: iphone5,
            },
            {
                id: 6,
                title: 'Questionário de Anamnese',
                text: 'Receba questionário customizados do seu personal para ter um acompanhamento mais detalhado e próximo da sua realidade. Informações como atualização de medidas, massa corporal e outras podem ser acompanhadas através desta ferramenta.\n',
                image: iphone6,
            },
            {
                id: 7,
                title: 'Organização dos treinos',
                text: 'Organize o dia, horário e modalidade do seu treino. Com isso, você tem uma agenda de tarefas a ser cumprida e computada.\n',
                image: iphone7,
            },
            {
                id: 8,
                title: 'Registro de intensidade dos treinos',
                text: 'Registre a intensidade das suas atividades para obter o cálculo de performance. A partir dele você entende melhor a sua rotina de exercícios. Dê o devido reconhecimento àquele dia intenso de treinamento!',
                image: iphone8,
            },
        ],
    }

    constructor(props) {
        super(props)
        this.breakPoints = [
            { width: 768, itemsToShow: 1 },
            { width: 1200, itemsToShow: 2, itemsToScroll: 2 },
        ]
    }

}

export default CarouselAluno
