import React from "react";

const Features = () => {
    window.location.replace("//www.quivon.app/features")
    return (
        <div>
        </div>
    )
}

export default Features;