import React, {Component} from "react";

import './Benefits.css';

import smile from '../../assets/icons/sorriso.png'
import people from '../../assets/icons/people-carry.png'
import layer from '../../assets/icons/layer-group.png'
import running from '../../assets/icons/running.png'
import arrow from '../../assets/icons/arrow-up.png'
import teacher from '../../assets/icons/Vector.png'
import list from '../../assets/icons/clipboard-list.png'
import card from '../../assets/icons/credit-card.png'

class BenefitsStudent extends Component {

    render() {

        const { items1, items2 } = this.state;

        return (
            <div className="cards__benefits">
                <div className="cards">
                    {items1.map(item =>
                        <div className="card">
                            <img src={item.image} alt="phone"/>
                            <h2>{item.title}</h2>
                            <p>
                                {item.text}
                            </p>
                        </div>
                    )}
                </div>
                <div className="cards">
                    {items2.map(item =>
                        <div className="card">
                            <img src={item.image} alt="phone"/>
                            <h2>{item.title}</h2>
                            <p>
                                {item.text}
                            </p>
                        </div>
                    )}
                </div>
            </div>

        )
    }

    state = {
        items1: [
            {
                id: 1,
                title: 'Motivação a mil',
                text: 'Crie projetos com amigos: viagens, eventos, datas especiais e competições. O início da evolução é perceber que o nosso compromisso aumentou.',
                image: smile,
            },
            {
                id: 2,
                title: 'Um puxando o outro',
                text: 'Encontre pessoas e projetos com a mesma finalidade, destino e ambição. Faça parte de um novo círculo de motivação, onde pessoas com o mesmo objetivo se encontram e se motivam.',
                image: people,
            },
            {
                id: 3,
                title: 'Verifique sua trajetória',
                text: 'Confira todo o seu histórico de treinamento, podendo verificar os níveis de intensidades que você se exercitou no respectivo dia. A melhor forma de evoluir é saber diferenciar acertos de erros.',
                image: layer,
            },
            {
                id: 4,
                title: 'Competição',
                text: 'Garanta prêmios e benefícios para vencedores e quem não saiu em vantagem. Quando existe algo valendo tudo fica mais emocionante e engajador.',
                image: running,
            },
        ],
        items2: [
            {
                id: 1,
                title: 'Acompanhamento de evolução',
                text: 'Acompanhe sua evolução através de registros de peso e foto. A melhor forma de se manter motivado é ver que o esforço está valendo a pena.',
                image: arrow,
            },
            {
                id: 2,
                title: 'Integração profissional',
                text: 'Obtenha o acompanhamento da sua evolução de uma forma simples e descomplicada. Use o aplicativo para enviar feedbacks, fotos da sua evolução, relatórios de acompanhamento e outros. ',
                image: teacher,
            },
            {
                id: 3,
                title: 'Ficha de treinamento',
                text: 'Adquira treino elaborado por seu profissional de confiança diretamente do aplicativo, com execuções corretas e modelos de exercícios gravados por nossa equipe. Tudo feito através de uma interface prática e fácil de usar.',
                image: list,
            },
            {
                id: 4,
                title: 'Gestão de Pagamentos',
                text: 'Realize o pagamento da sua consultoria de forma automática e sem complicações. ',
                image: card,
            },
        ]
    }

}

export default BenefitsStudent