import React, { useState } from 'react'
import './styles.css'
import logo from '../../assets/icons/favicon.png'
import backgroundTop from '../../assets/quivon_banner.png'
import docLogo from '../../assets/icons/logoDoc.png'
import searchIcon from '../../assets/icons/search.svg'
import plusIcon from '../../assets/icons/iconsHelp/plus.svg'
import minusIcon from '../../assets/icons/iconsHelp/minus.svg'
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';

import { links_doubts } from '../Help/links_doubts';
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material'

const Help = () => {
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [iconAccordionOpen, setIconAccordionOpen] = useState(false)

    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleSearchClick = () => {
        if (searchValue === '') {
            setSearchResults(links_doubts);
        } else {
            const filteredLinks = links_doubts.filter((question) => {
                const filteredDoubts = question.doubts.filter((doubt) =>
                    doubt.name.toLowerCase().includes(searchValue.toLowerCase())
                );
                return question.topic.toLowerCase().includes(searchValue.toLowerCase()) || filteredDoubts.length > 0;
            });

            setSearchResults(filteredLinks);
        }
    };

    const onKeyDown = (e) => e.key === 'Enter' && handleSearchClick()

    const toggleAccordion = () => {
        const updatedLinks = links_doubts.map((link) => {
            if (link.topic === "Acesso ao FOR PARTNERS") {
                return {
                    ...link,
                    isAccordionOpen: !link.isAccordionOpen
                };
            }
            return link;
        });
        setSearchResults([]);
        setShowMenu(false);
        links_doubts = updatedLinks;
    };

    const filterInactiveItems = () => {
        const inactiveItems = {
            topics: [],
        };

        for (const topic of links_doubts) {
            const inactiveDoubts = topic.doubts.filter((doubt) => doubt.status === 'inactive');
            if (inactiveDoubts.length > 0) {
                inactiveItems.topics.push({
                    topic: topic.topic,
                    doubts: inactiveDoubts,
                });
            }
        }

        return inactiveItems;
    };


    const inactiveItems = filterInactiveItems();

    const newInativeListItems = inactiveItems.topics[0].doubts






    return (
        <div className='container'>
            <div className='header'>
                <div className='left'>
                    <img src={logo} alt="logotipo" className='logotipo' />
                    <h1>CENTRAL DE AJUDA</h1>
                </div>
                <div className='right' style={{ width: '60%' }}>
                    <div className='mobile-menu' onClick={toggleMenu}>
                        <div className='line'></div>
                        <div className='line'></div>
                        <div className='line'></div>
                    </div>


                    <div className={showMenu ? 'links_header active' : 'links_header'}
                        onClick={toggleAccordion}
                    >
                        {links_doubts.map((question, index) => {
                            return (
                                <Link dangerouslySetInnerHTML={{ __html: question.topic }} to={question.doubts.link} className='link_header'>
                                </Link>
                            )
                        })}
                    </div>

                </div>
            </div>
            <div className='welcome' style={{ backgroundImage: `url(${backgroundTop})`, backgroundSize: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <div>
                    <p>Bem-vindo à Central de Ajuda da Quivon</p>
                    <img src={docLogo} alt="" style={{ height: 102 }} />

                </div>
                <h1>O que podemos ajudar você a encontrar?</h1>
                <div className='container_search'>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            placeholder="busca"
                            className="input_search"
                            onChange={handleSearchChange}
                            onKeyDown={onKeyDown}
                        />
                        <button
                            className="search_icon"
                            onClick={handleSearchClick}
                        >
                            <img src={searchIcon} alt={''} />
                        </button>
                    </div>
                </div>

            </div>
            <div className='questions'>
                {searchResults.length === 0 ? (
                    // Exibir todos os itens quando searchResults estiver vazio
                    links_doubts.map((question, index) => (
                        <div className='cardQuestion' key={question.topic + index}>
                            <h1 style={{ display: 'flex', alignItems: 'center' }}><img style={{ height: 25 }} src={question.icon} /> {question.topic}</h1>
                            <div style={{ display: 'flex', flexDirection: 'column', height: '30%' }}>
                                {question.doubts.map((doubt, subIndex) => (
                                    doubt.status == 'active' && <div className='cardLinkQuestion' key={doubt.name + subIndex}>
                                        {doubt.name === 'Acesso ao FOR PARTNERS' ?
                                            <Accordion>
                                                <AccordionSummary style={{ padding: 0, display: 'flex', alignItems: 'center', margin: 0 }}>
                                                    <img src={iconAccordionOpen ? minusIcon : plusIcon} style={{height: 15, marginTop: 5}}/>
                                                    <Typography onClick={() => setIconAccordionOpen(!iconAccordionOpen)} style={{ color: '#067ACC', fontFamily: "'Bebas Neue', sans-serif", padding: 2, margin: 0 }}>
                                                        Acesso ao FOR PARTNERS
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ padding: 0 }}>
                                                    <Typography style={{ display: 'flex', flexDirection: 'column' }}>
                                                        {newInativeListItems.map((link) => {
                                                            return (
                                                                <Link style={{ borderBottom: '1px solid #D3DCE3', fontFamily: "'Bebas Neue', sans-serif" }} to={link.link}>{link.name}</Link>
                                                            )
                                                        })}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion> :
                                            <Link to={doubt.link} style={{ marginTop: 4 }}>
                                                {doubt.name} {doubt.iconName && <img style={{ height: 15, background: '#067ACC', borderRadius: '50%', padding: 3 }}
                                                    src={doubt.iconName} />}
                                            </Link>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                ) : (
                    // Exibir apenas os resultados da pesquisa quando searchResults não estiver vazio
                    searchResults.map((question, index) => (
                        <div className='cardQuestion' key={question.topic + index}>
                            <h1>{question.topic}</h1>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '30vh' }}>
                                {question.doubts.map((doubt, subIndex) => (
                                    <div className='cardLinkQuestion' key={doubt.name + subIndex}>


                                        <Link to={doubt.link} style={{ marginTop: 4 }}>{doubt.name}</Link>

                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                )}
            </div>


        </div>
    )
}

export default Help;
