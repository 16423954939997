import React from 'react'

import 'animate.css';

import '../global.css'

import Logo from '../assets/quivon.svg'

import ulyssesPhoto from "../assets/2.jpg"
import bannerUlysses from "../assets/banner-ulysses2.png"

import manEvolution from "../assets/team_ulysses_photo_man.jpg"
import manEvolution2 from "../assets/team_ulysses_photo_man2.jpg"
import manEvolution3 from "../assets/team_ulysses_photo_man3.jpg"
import manEvolution5 from "../assets/team_ulysses_photo_man5.jpg"
import manEvolution6 from "../assets/team_ulysses_photo_man6.jpg"
import manEvolution7 from "../assets/team_ulysses_photo_man7.jpg"
import manEvolution8 from "../assets/team_ulysses_photo_man8.jpg"


import womanEvolution from "../assets/team_ulysses_photo_woman.jpg"
import womanEvolution2 from "../assets/team_ulysses_photo_woman2.jpg"
import womanEvolution6 from "../assets/team_ulysses_photo_woman6.jpg"
import womanEvolution7 from "../assets/team_ulysses_photo_woman7.jpg"
import womanEvolution8 from "../assets/team_ulysses_photo_woman8.jpg"

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';

const ulysses = () => {

    const EvolutionPhotos = [
        {
            id: 1,
            photo: manEvolution,
        },
        {
            id: 2,
            photo: manEvolution2,
        },
        {
            id: 3,
            photo: manEvolution3,
        },
        {
            id: 4,
            photo: manEvolution5,
        },
        {
            id: 5,
            photo: womanEvolution
        },
        {
            id: 6,
            photo: womanEvolution2
        },
        {
            id: 8,
            photo: womanEvolution6
        },
        {
            id: 9,
            photo: womanEvolution7
        },
        {
            id: 10,
            photo: womanEvolution8
        },
        {
            id: 11,
            photo: manEvolution6
        },
        {
            id: 12,
            photo: manEvolution7
        },
        {
            id: 13,
            photo: manEvolution8
        }
    ]

    const TutorialSteps = [
        {
            name: 'Entender o seu nível',
            image: 'https://youtube.com/shorts/tXY8p__yT60',
        },
        {
            name: 'Prescrição do treinamento',
           image: 'https://youtube.com/shorts/9b-ksKnYBUk'
        },
        {
            name: 'Progressão de intensidade',
            image: 'https://youtube.com/shorts/m0S_X1VYjqQ'
        },
    ]

    return (
        <div className='container_something' style={{ background: '#3770f4' }}>
            <div>
                <img className="analise_logo" src={Logo} />
            </div>
            <div className='section_1'>
                <h1 className='animate__animated animate__fadeInRight'>
                    HIPERTROFIA MÁXIMA - TREINADOR<br /> ULYSSES PEDRO
                </h1>

                <img src={bannerUlysses} />

                <div className='tutorial_cupom'>
                    <h1>Receba acompanhamento de forma online pelo APP:</h1>
                    <div className='tutorial_cupom'>
                        <Carousel labels={{ leftArrow: 'red' }} autoPlay infiniteLoop showStatus={false} showThumbs={false}>
                            {TutorialSteps.map((step, index) => {
                                return (
                                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                        <h4 style={{paddingBottom: 15}}>
                                            <span>{index+1}º {step.name}</span> 
                                        </h4>
                                        
                                        <ReactPlayer
                                        style={{borderRadius: 10, border: '1px solid white'}}
                                            width={300}
                                            height={400}
                                            url={step.image}
                                            controls
                                        />
                                    </div>

                                )
                            })}
                        </Carousel>
                    </div>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <span style={{ width: '80%' }} className='animate__animated animate__fadeInRight animate__delay-1s'>
                            Programa de treinamentos INICIANTE, INTERMEDIÁRIO ou AVANÇADO com uma das maiores REFERÊNCIAS na área de Educação Física.
                            CONFIRA AGORA AS TRANSFORMAÇÕES JÁ FEITAS PELO ULYSSES.
                        </span>
                    </div>

                    <Carousel autoPlay infiniteLoop showStatus={false} showThumbs={false}>
                        {EvolutionPhotos.map(evolutionPhoto => {
                            return (
                                <div>
                                    <div>
                                        <img src={evolutionPhoto.photo} style={{ width: 300, height: 400 }} />
                                    </div>
                                </div>
                            )
                        })}
                    </Carousel>

                    <div>
                        <button href="" style={{ marginTop: 15 }}>
                            <a href="https://wa.me/5511988703718?text=Tenho%20interesse%20em%20come%C3%A7ar%20a%20consultoria%20do%20Ulysses" style={{ textDecoration: 'none', color: '#3770f4' }}>SIM, QUERO ENTRAR PARA O TIME</a>
                        </button>
                    </div>
                </div>

                <div className='section_2'>
                    <div className='section_2_texts' style={{ background: '#fff' }}>
                        <h1 className='animate__animated animate__fadeInUp animate__delay-4s' style={{ width: '100%' }}>
                            MAS ANTES, QUERO QUE VOCÊ CONHEÇA MAIS<br /> SOBRE MIM.
                        </h1>

                        <div className='section_2_left' style={{ margin: '0 30px' }}>
                            <div className='section_left_right' style={{ display: 'flex', width: '100%' }}>
                                <div className='list_tips animate__animated animate__fadeInUp animate__delay-4s'>
                                    <p>Ulysses Pedro é empresário, Coach com pós graduação em Bodybuilder Coach, Graduado em Educação Física e Nutrição esportiva, Pós graduado em fisiologia do exercício e juíz em uma das principais organizações esportivas do fisiculturismo (WBPF). Como treinador, já possui mais de 108 títulos, dentre eles, 1 título do Arnold PRO, uma das maiores competições de fisiculturismo do mundo. Além disso, Ulysses possui mais de 580 clientes satisfeitos e tem paixão pela disseminação do conhecimento, instruindo pessoas do iniciante ao fisiculturista.</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={ulyssesPhoto} style={{ width: 250, background: '#3770f4', borderRadius: '35px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='section_3'>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <h1 style={{ width: '80%' }}>NÃO TRANSFORMO APENAS CORPOS E SIM A SUA MENTALIDADE. NÃO PERCA TEMPO COM BLÁ-BLÁ-BLÁ DE TREINAMENTOS. TREINE COM OS<br /> MAIORES.</h1>
                        </div>

                        <div className='cards_section_3'>
                            <div className='card_section_3'>
                                <h1>PLANO DE TREINO ULYSSES
                                </h1>
                                <hr />
                                <div className='benefits_card'>
                                    <h4>Treinos INICIANTE, INTERMEDIÁRIO e AVANÇADO</h4>
                                    <h4>Foco em HIPERTROFIA MÁXIMA</h4>
                                    <h4>Contabilizadores de Treino</h4>
                                    <h4>Acompanhe sua carga ao longo do tempo</h4>
                                    <h4>Acompanhe sua evolução</h4>
                                    <h4>Treino enviado em um APLICATIVO</h4>
                                    <h4>Suporte app 24horas</h4>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                    <h6>A partir de:</h6>
                                    <h5>R$99,00</h5>
                                    <div>
                                        <button className='btn_contact'>
                                            <a href="https://wa.me/5511988703718?text=Tenho%20interesse%20em%20come%C3%A7ar%20a%20consultoria%20do%20Ulysses" style={{ textDecoration: 'none', color: '#fff' }}>ENTRAR EM CONTATO</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 15 }}>
                        <span>Todos os direitos reservados | Ulysses e Comercio de Produtos Fitness LTDA© | 2021</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ulysses
