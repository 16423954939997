import React, { useState } from "react";
import "./ToggleSwitch.css";

function ToggleSwitchFunctionalities(props) {
    const [isToggled, setIsToggled] = useState(false);
    const onToggle = () => setIsToggled(!isToggled);
    return (
        <div className="toggle">
            <label className="toggle-switch">
                <input type="checkbox" checked={isToggled} onClick={onToggle} style={{content: 'aaa'}}/>
                <span className="switch" onClick={props.sliderFunctionalitiesClick}>
                    <h3>Para o aluno</h3>
                    <h3>Para o personal</h3>
                </span>
            </label>
        </div>
    );
}
export default ToggleSwitchFunctionalities;
