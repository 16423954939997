import React, { Component } from "react";

import Toolbar from '../components/Toolbar/Toolbar'
import ToggleSwitchFunctionalities from '../components/ToggleSwitch/ToggleSwitchFunctionalities'
import ToggleSwitchBenefits from '../components/ToggleSwitch/ToggleSwitchBenefits'
import Footer from '../components/Footer/Footer'
import CarouselAluno from "../components/Carousel/CarouselAluno"
import BenefitsStudent from '../components/Benefits/BenefitsStudent'
import SideDrawer from '../components/SideDrawer/SideDrawer'


import '../global.css'

import mackbook from "../assets/mackbook.png"
import corrida from "../assets/mulheralta.png"
import iphone1 from "../assets/iphone1.png"
import iphone2 from "../assets/iphone2.png"
import iphone3 from "../assets/iphone3.png"
import apple from "../assets/icons/apple-logo.svg"
import google from "../assets/icons/Google__G__Logo.svg"
import CarouselPersonal from "../components/Carousel/CarouselPersonal";
import BenefitsPersonal from "../components/Benefits/BenefitsPersonal";


class Home extends Component{
    state = {
        sideDrawerOpen: false,
        functionalitiesChange:true,
        benefitsChange: false,
    }
    drawerClickHandler = () => {
        this.setState((prevState) => {
            return {sideDrawerOpen: !prevState.sideDrawerOpen}
        })
    }
    sliderFunctionalitiesClick = () => {
        this.setState((prevState) => {
            return {functionalitiesChange: !prevState.functionalitiesChange}
        })
    }

    sliderBenefitsClick = () => {
        this.setState((prevState) => {
            return {benefitsChange: !prevState.benefitsChange}
        })
    }
  render() {
        let sideDrawer;
        let carouselAluno;
        let carouselPersonal;
        let benefitsAluno;
        let benefitsPersonal;

        if (this.state.sideDrawerOpen) {
            sideDrawer = <SideDrawer drawerClickHandler={this.drawerClickHandler}/>;
        }

      if (this.state.functionalitiesChange) {
          carouselAluno = <CarouselAluno/>
      } else {
          carouselPersonal = <CarouselPersonal/>
      }

      if (this.state.benefitsChange) {
          benefitsAluno = <BenefitsPersonal/>
      } else {
          benefitsPersonal = <BenefitsStudent/>
      }

      window.location.replace("//www.quivon.app/")

    return (
        <div>
            <main className="main" style={{display: "none"}}>
                <div className="open__demonstration">
                    <div className="marketplaces">
                        <h1>
                            O acelerador da sua
                            <br/>
                            evolução está aqui.
                        </h1>
                        <p>
                            Aumente a frequência e intensidade dos seus treinos com
                            <br/>
                            o que há de mais inovador no fitness.
                        </p>
                        <div className="links__download">
                            <div>
                            <button className="link__download">

                                <a href="https://apps.apple.com/br/app/motivapp/id1507700739">
                                    <img alt="Apple" width={30} height={30} src={apple} style={{marginTop: 1}}/>
                                    Disponível na
                                    <br/>
                                    App Store
                                </a>
                            </button>
                            </div>
                            <div>
                                <button className="link__download">
                                    <a href="https://play.google.com/store/apps/details?id=br.com.motivapp.motiva&hl=pt">
                                        <img alt="Google" width={30} src={google} style={{marginTop: 5}}/>
                                        Disponível na
                                        <br/>
                                        Google Play
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="demonstration__images">
                        <div className="item1">
                            <img src={iphone3} alt="Iphone" className="iphone3"/>
                            <img src={iphone2} alt="Iphone" className="iphone2"/>
                        </div>
                        <div className="item2">
                            <img src={iphone1} alt="Iphone" className="iphone1"/>
                            <img src={iphone1} alt="Iphone" className="iphone__mobile"/>
                        </div>
                    </div>
                </div>
                <div className="functionalities" id="functionalities">
                    <div className="title__functionalities">
                        <h2>
                            Funcionalidades
                        </h2>
                    </div>
                    <div>
                        <ToggleSwitchFunctionalities sliderFunctionalitiesClick={this.sliderFunctionalitiesClick}/>
                    </div>
                    {carouselAluno}
                    {carouselPersonal}
                </div>
                <div className="schedule">
                    <div className="talk__to__us">
                        <h3>
                            Vamos bater um papo?
                        </h3>
                        <p>
                            Agende uma
                            <br/>
                            demonstração e conheça
                            <br/>
                            nossa plataforma
                        </p>
                        <button>
                            <a href="https://calendly.com/comercialquivon/30min?month=2021-10">
                                Agende uma conversa
                            </a>
                        </button>
                    </div>
                    <img src={mackbook} alt="mackbook"/>
                </div>
                <div className="benefits" id="benefits">
                    <div className="title__benefits">
                        <h2>
                            Benefícios
                        </h2>
                    </div>
                    <ToggleSwitchBenefits sliderBenefitsClick={this.sliderBenefitsClick}/>
                    {benefitsAluno}
                    {benefitsPersonal}
                </div>
                <div className="download__app" id="download__app">
                    <div className="download__links">
                        <h3>
                            Download App
                        </h3>
                        <p>
                            Acelere seus resultados com esse aplicativo de
                            <br/>
                            gestão e motivação fitness
                        </p>
                        <div className="links__download">
                            <div>
                                <button className="link__download">

                                    <a href="https://apps.apple.com/br/app/motivapp/id1507700739">
                                        <img alt="Apple" width={30} src={apple}/>
                                        Disponível na
                                        <br/>
                                        App Store
                                    </a>
                                </button>
                            </div>
                            <div>
                                <button className="link__download">
                                    <a href="https://play.google.com/store/apps/details?id=br.com.motivapp.motiva&hl=pt">
                                        <img alt="Google" width={30} src={google} style={{marginTop: 5}}/>
                                        Disponível na
                                        <br/>
                                        Google Play
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                    <img src={corrida} alt="Corrida" className="run"/>
                </div>
            </main>
        </div>
    );
  }
}

export default Home;
