import React, { Component } from "react";
import '../global.css'

class Politica extends Component {

    render() {

        return (
            <div className='termos-page'>
                <p>Bem-vindo à Quivon.</p>
                <br /><p>Termos de Uso</p>
                <br /><p>Este Termo de Uso se aplica exclusivamente ao serviço conta Quivon.</p>
                <br /><p>Ao se registrar e utilizar os serviços, você confirma que leu e compreendeu o Termo de Uso e o Aviso de Privacidade aplicáveis ao serviço e concorda em ficar vinculado a eles.</p>
                <br /><p>DESCRIÇÃO DOS SERVIÇOS:</p>
                <br /><p>A Quivon é uma Plataforma em que os Usuários poderão acessar os protocolos de treino e atividades relacionadas que foram prescritas pelo Profissional escolhido, bem como, realizar o registro de sua evolução e permitir o acompanhamento da sua jornada.</p>
                <br /><p>Neste documento, você será considerado como “Usuário”, a Quivon  como “Plataforma” e o Profissional da Saúde/Professor de Educação Física/Personal Trainer como “Profissional”, “treinamento personal” é o programa de treinamento elaborado e monitorado pelo Profissional.</p>
                <br /><p>A Quivon atua como uma plataforma de tecnologia, neste sentido Usuário poderá ter acesso às ferramentas gratuitas de planejamento e acompanhamento de programas de treinos e interação com outros Usuários, bem como a área destinada ao treinamento personalizado que é contratado diretamente com um Profissional e intermediado por meio da plataforma.</p>
                <br /><p>As regras que regem as obrigações entre os Usuários e os Profissionais contratados devem ser observadas na página exclusiva dos Profissionais. Neste sentido, o Usuário deverá se atentar aos prazos, valores e formatos em que o serviço será prestado. Importante ressaltar que o Profissional tem total liberdade para desenvolver o programa contratado, bem como estipular os prazos e valores serão aplicados caso a caso.</p>
                <br /><p>Para ter acesso à página de treinamento personal é necessário inserir o código enviado pelo seu Profissional, que é responsável por toda sua jornada neste módulo.</p>
                <br /><p>Toda a jornada do Usuário em um programa desenvolvido por um Profissional dentro da plataforma é elaborada exclusivamente pelo Profissional contratado, o que inclui feedbacks, planejamentos, treinamentos, comentários, respostas, perguntas e anamneses, entre outros elementos. Neste sentido, a Quivon não é responsável pelo conteúdo inserido pelo Profissional.</p>
                <br /><p>Todas as regras de contratação do Profissional serão descritas na página de contratação, é fundamental que o Usuário se atente aos prazos, formatos e serviços que são oferecidos pelo Profissional na hora da contratação.</p>
                <br /><p>DIREITOS DOS USUÁRIOS:</p>
                <br /><p>Direito à privacidade: Os usuários têm o direito de ter seus dados pessoais e informações confidenciais protegidos de acordo com as políticas de privacidade da Quivon. A plataforma adota medidas de segurança para garantir a confidencialidade e o uso adequado das informações compartilhadas.</p>
                <br /><p>Direito à transparência: Os usuários têm o direito de receber informações claras e transparentes sobre os serviços oferecidos pela Quivon, incluindo políticas de pagamento, termos de uso e quaisquer taxas ou encargos envolvidos na utilização da plataforma.</p>
                <br /><p>Direito à segurança: A Quivon se esforça para garantir um ambiente seguro para as transações entre Usuários e Profissionais. A plataforma implementa medidas de segurança para proteger os dados e informações pessoais dos usuários, bem como para prevenir atividades fraudulentas.</p>
                <br /><p>Direito à qualidade do serviço: Os Usuários têm o direito de receber serviços de qualidade dos Profissionais contratados por meio da plataforma. A Quivon incentiva a prestação de serviços de alta qualidade e fornece mecanismos para que os Usuários possam avaliar e fornecer feedbacks.</p>
                <br /><p>Direito ao suporte e atendimento ao cliente: A Quivon se compromete a oferecer suporte e atendimento ao cliente eficientes para atender às necessidades e dúvidas dos Usuários. A plataforma disponibiliza canais de comunicação para auxiliar os usuários em suas interações e resolver eventuais problemas.</p>
                <br /><p>RESPONSABILIDADES DOS USUÁRIOS:</p>
                <br /><p>Uso adequado: Os usuários da Quivon são responsáveis por utilizar a plataforma de forma adequada, respeitando os termos de uso, políticas e regulamentos aplicáveis. Isso inclui fornecer informações precisas, atualizadas e completas ao se cadastrar na plataforma, bem como garantir que as atividades realizadas estejam de acordo com a legislação vigente.</p>
                <br /><p>Conduta ética: Os usuários devem agir de forma ética, respeitando os direitos e a privacidade dos outros usuários, freelancers e terceiros envolvidos nas transações. Isso implica em não utilizar a plataforma para atividades ilegais, difamatórias, discriminatórias, fraudulentas ou prejudiciais.</p>
                <br /><p>Comunicação Clara e Respeitosa: Os usuários são responsáveis por manter uma comunicação clara, respeitosa e profissional com os freelancers e outros usuários da Quivon. Isso inclui fornecer informações relevantes de forma precisa, responder prontamente às mensagens e tratar os outros com cortesia e respeito.</p>
                <br /><p>Verificação e Avaliação: Os usuários têm a responsabilidade de verificar as informações e qualificações dos Profissionais antes de contratá-los, bem como fornecer feedback e avaliações justas e precisas sobre os serviços recebidos. Essas ações contribuem para a transparência e qualidade da plataforma.</p>
                <br /><p>Pagamentos e Acordos Financeiros: Os usuários são responsáveis por efetuar os pagamentos acordados pelos serviços contratados, dentro dos prazos estabelecidos. Também é importante garantir que as informações de pagamento fornecidas sejam precisas e atualizadas.</p>
                <br /><p>Segurança e Proteção de Dados: Os usuários devem tomar as medidas necessárias para proteger suas informações de acesso à conta, senhas e dados pessoais. É responsabilidade dos usuários manter essas informações confidenciais e notificar imediatamente a Quivon caso suspeitem de qualquer atividade não autorizada em suas contas.</p>
                <br /><p>É essencial que os usuários compreendam e assumam essas responsabilidades ao utilizar a plataforma Quivon, pois elas contribuem para um ambiente seguro, confiável e produtivo para todos os envolvidos.</p>
                <br /><p>RESPONSABILIDADES DA QUIVON:</p>
                <br /><p>Plataforma de comunicação e monitoramento: A Quivon é responsável por fornecer uma plataforma de interação entre o Profissional e o Usuário, facilitando a prestação de serviços. Isso inclui disponibilizar a plataforma tecnológica, recursos e ferramentas necessárias para a realização das transações.</p>
                <br /><p>Proteção dos Dados: A Quivon se compromete a adotar medidas razoáveis para proteger os dados e informações fornecidos pelos usuários, de acordo com as leis de proteção de dados aplicáveis. A empresa também se compromete a não compartilhar informações pessoais dos usuários sem o devido consentimento, exceto quando exigido por lei.</p>
                <br /><p>Suporte ao Usuário: A Quivon se esforça para fornecer suporte e assistência aos usuários em relação ao uso da plataforma, esclarecendo dúvidas e solucionando problemas técnicos. O suporte ao usuário pode ser realizado por meio de canais de comunicação designados pela Quivon, como e-mail ou chat.</p>
                <br /><p>Segurança da Plataforma: A Quivon é responsável por adotar medidas de segurança adequadas para proteger a plataforma contra acesso não autorizado, ataques cibernéticos ou outras ameaças. No entanto, os usuários também devem assumir a responsabilidade de proteger suas informações de acesso e tomar precauções de segurança ao utilizar a plataforma.</p>
                <br /><p>Melhoria Contínua: A Quivon se compromete a buscar constantemente a melhoria da plataforma, levando em consideração os feedbacks dos usuários e implementando atualizações e aprimoramentos que possam beneficiar a experiência do usuário.</p>
                <br /><p>A Quivon atua como uma plataforma de tecnologia, neste sentido Usuário poderá ter acesso às ferramentas gratuitas de planejamento e interação com outros Usuários, bem como a área destinada ao treinamento personalizado que é contratado diretamente com um Profissional e intermediado por meio da plataforma.</p>
                <br /><p>As regras que regem as obrigações entre os Usuários e os Profissionais contratados devem ser observadas na página exclusiva dos Profissionais. Neste sentido, o Usuário deverá se atentar aos prazos, valores e formatos em que o serviço será prestado. Importante ressaltar que o Profissional tem total liberdade para desenvolver o programa contratado, bem como estipular os prazos e valores serão aplicados caso a caso.</p>
                <br /><p>É importante que os usuários estejam cientes das responsabilidades assumidas pela Quivon, pois isso demonstra o compromisso da empresa em fornecer uma plataforma confiável e segura para facilitar as transações entre Usuários e os Profissionais.</p>
                <br /><p>PROPRIEDADE INTELECTUAL</p>
                <br /><p>Direitos Autorais: Todos os conteúdos e materiais presentes no Site e no App Mobile da Quivon, incluindo textos, imagens, gráficos, logotipos, ícones, áudios, vídeos, software e qualquer outro elemento, são protegidos por direitos autorais. Esses direitos pertencem à Quivon ou a terceiros que autorizaram seu uso pela empresa.</p>
                <br /><p>Uso Restrito: É proibido copiar, reproduzir, modificar, distribuir, transmitir, exibir, publicar, vender, licenciar ou explorar qualquer conteúdo do Site e do App Mobile da Quivon sem a autorização prévia por escrito da empresa ou do detentor dos direitos autorais.</p>
                <br /><p>Propriedade do Conteúdo: Ao utilizar a plataforma da Quivon, os usuários reconhecem que o conteúdo fornecido por eles é de sua propriedade ou possuem autorização para utilizá-lo. No entanto, ao fazer uso da plataforma, os usuários concedem à Quivon uma licença não exclusiva e gratuita para utilizar, reproduzir e exibir esse conteúdo com o objetivo de fornecer os serviços e promover a plataforma.</p>
                <br /><p>Respeito aos Direitos de Terceiros: Os usuários devem garantir que o conteúdo fornecido não viole os direitos de propriedade intelectual de terceiros, como direitos autorais, marcas registradas ou segredos comerciais. A Quivon não se responsabiliza por qualquer violação desses direitos pelos usuários.</p>
                <br /><p>Denúncia de Violação: Caso os usuários identifiquem qualquer conteúdo no Site ou do App Mobile da Quivon que infrinja seus direitos de propriedade intelectual, eles podem entrar em contato com a empresa para relatar a violação. A Quivon se compromete a investigar as denúncias e, se for o caso, tomar as medidas apropriadas, como a remoção do conteúdo infrator.</p>
                <br /><p>A Quivon reconhece a importância da proteção da propriedade intelectual e está empenhada em respeitar os direitos autorais e outros direitos de propriedade intelectual de terceiros. Os usuários devem agir de forma responsável e ética, garantindo que o conteúdo que compartilham não viole esses direitos.</p>
                <br /><p>CADASTRO DE USUÁRIOS:</p>
                <br /><p>A Quivon disponibiliza seus serviços para as pessoas que possuam capacidade legal para contratar tais serviços. É importante ressaltar que indivíduos que não possuam essa capacidade, incluindo menores de idade e pessoas que tenham sido inabilitadas pela Quivon, temporária ou permanentemente, não estão autorizados a utilizar os serviços se não forem devidamente representadas, devendo estas informações serem inseridas no momento do cadastro. Os Usuários devem estar cientes de que a legislação civil prevê sanções para o descumprimento dessas condições.</p>
                <br /><p>Para garantir a integridade do sistema, é expressamente proibida a criação de mais de um cadastro por Usuário. Caso seja identificada a existência de múltiplos cadastros associados a um único Usuário, a Quivon se reserva o direito de a seu exclusivo critério e sem necessidade de aviso prévio, desabilitar todos os cadastros existentes e impedir a realização de novos cadastros relacionados a essas informações.</p>
                <br /><p>Destacamos que é permitida apenas a vinculação de um único cadastro por CPF/CNPJ número de telefone ou endereço de e-mail. Dessa forma, é estritamente proibida a duplicidade de dados em qualquer circunstância.</p>
                <br /><p>A Quivon reserva-se o direito de, a seu critério exclusivo, excluir o cadastro de Usuários caso seja constatado que a conduta do Usuário é ou será prejudicial ou ofensiva a outros Usuários, à Quivon, seus funcionários ou a terceiros. Essa medida visa garantir um ambiente seguro e respeitoso para todos os envolvidos na plataforma.</p>
                <br /><p>Registro na Plataforma: Para se cadastrar como Usuário na plataforma da Quivon é necessário preencher um formulário de cadastro fornecendo informações precisas e atualizadas. O Usuário é responsável por garantir a veracidade e a integridade dos dados fornecidos.</p>
                <br /><p>Verificação de Identidade: A Quivon poderá solicitar ao Usuário a comprovação de sua identidade, mediante o envio de documentos oficiais válidos. Essa verificação é feita com o objetivo de garantir a segurança e a confiabilidade da plataforma.</p>
                <br /><p>Responsabilidade do Usuário: Ao se cadastrar na plataforma, o Usuário concorda em cumprir com todas as obrigações e responsabilidades estabelecidas nos Termos de Uso da Quivon, bem como com as leis, normas e regulamentos aplicáveis. O Usuário é responsável por manter seu perfil e informações atualizadas, assim como pela qualidade, entrega e pagamento dos serviços prestados/contratados.</p>
                <br /><p>SISTEMA DE PAGAMENTO:</p>
                <br /><p>Na Quivon o Usuário poderá realizar o pagamento do seu plano personal por meio da Plataforma, garantindo segurança na transação.</p>
                <br /><p>Além disso, a Quivon utiliza uma plataforma de pagamento parceira para facilitar as transações financeiras. </p>
                <br /><p>A Quivon pode oferecer diversas opções de pagamento dentro de sua plataforma, como o pagamento por meio de cartão de crédito e outras modalidades. No entanto, é importante ressaltar que a Quivon reserva-se o direito de modificar, adicionar ou reduzir os mecanismos de pagamento disponibilizados, visando sempre melhorias e adequações às necessidades dos usuários e às exigências do mercado.</p>
                <br /><p>Dessa forma, ao utilizar a Quivon, os Usuários podem contar com uma intermediação de pagamento transparente e confiável.</p>
                <br /><p>Em conformidade com legislação consumerista o Usuário tem o prazo de 7 dias contados da contratação para cancelar o plano e receber a devolução do seu pagamento.</p>
                <br /><p>Este pedido de cancelamento deverá ser realizado diretamente ao Profissional que irá realizar o procedimento necessário.</p>
                <br /><p>PROIBIÇÕES:</p>
                <br /><p>É expressamente proibido reproduzir, duplicar, copiar, vender, revender ou explorar comercialmente o conteúdo da Quivon sem o prévio consentimento por escrito dos proprietários da plataforma. Além disso, fica estritamente proibido o uso de técnicas de mineração de dados, robôs ou outras ferramentas de coleta e extração de dados com o objetivo de extrair, de forma isolada ou recorrente, qualquer parte significativa da Quivon para reutilização. Os usuários estão cientes e concordam que a violação dessas restrições pode resultar em medidas legais e responsabilidade por danos.</p>
                <br /><p>RELAÇÕES COM TERCEIROS:</p>
                <br /><p>A Quivon pode fornecer links para outros sites, no entanto, isso não significa que esses sites sejam de propriedade ou operados pela Quivon.</p>
                <br /><p>Por não ter controle sobre esses sites, a Quivon não se responsabiliza pelos conteúdos, práticas e serviços oferecidos por eles.</p>
                <br /><p>A presença de links para outros sites não implica em uma relação de parceria, supervisão, cumplicidade ou solidariedade da Quivon com esses sites e seus conteúdos.</p>
                <br /><p>PENALIDADES:</p>
                <br /><p>A Quivon reserva-se o direito de suspender, por tempo indeterminado, qualquer conta que seja considerada necessária para manter a segurança e integridade da plataforma, caso isso represente uma violação aos direitos dos usuários.</p>
                <br /><p>DISPONIBILIDADE:</p>
                <br /><p> A Plataforma está disponível para uso do Usuário "no estado em que se encontra" e "conforme disponível".</p>
                <br /><p>A Quivon não se responsabiliza por garantias implícitas de comerciabilidade, adequação a um fim específico e retorno financeiro. Além disso, não garante que os serviços da Plataforma serão fornecidos sem interrupções ou erros.</p>
                <br /><p>Os Usuários reconhecem que assumem todo o risco decorrente do uso da nossa Plataforma, sendo de sua exclusiva responsabilidade.</p>
                <br /><p>INTERRUPÇÕES DA PLATAFORMA: </p>
                <br /><p>O acesso à Plataforma pode ser interrompido, suspenso ou ficar temporariamente intermitente, sem aviso prévio, devido a falhas de sistema ou servidor, manutenção, alterações nos sistemas ou por razões fora do controle da Quivon, sem que haja qualquer indenização ou reparação.</p>
                <br /><p>ALTERAÇÕES E DESCONTINUIDADE: A Quivon reserva-se o direito de, a qualquer momento e a seu exclusivo critério, de acordo com sua disponibilidade comercial, alterar qualquer aspecto da Plataforma, incluindo suas características ou funcionalidades.</p>
                <br /><p>Da mesma forma, a Quivon pode suspender, cancelar ou descontinuar a Plataforma, total ou parcialmente, de forma temporária ou permanente, mediante aviso aos Usuários.</p>
                <br /><p>Essas modificações não constituirão quebra deste Termo, nem darão direito a indenização ou multa em favor dos Usuários, exceto a obrigação de reembolsar valores eventualmente pagos antecipadamente.</p>
                <br /><p>DADOS PESSOAIS:</p>
                <br /><p>Para garantir a qualidade da Plataforma e permitir que os Usuários obtenham resultados de forma rápida e segura, é necessário fornecer dados pessoais, conforme estabelecido na Política de Privacidade.</p>
                <br /><p>MODIFICAÇÕES:</p>
                <br /><p> A Quivon reserva-se o direito de modificar os Termos e Condições de Uso a qualquer momento, bem como alterar a aparência ou o funcionamento da Plataforma, sem qualquer obrigação perante os Usuários. Essas modificações entrarão em vigor imediatamente após serem comunicadas por e-mail ou disponibilizadas na Internet, exceto quando as atualizações dos Termos e Condições de Uso dispuserem de forma diferente.</p>
                <br /><p>Entende-se que o Usuário aceita as alterações dos Termos caso não se manifeste em contrário ou continue acessando normalmente a Plataforma.</p>
                <br /><p>NULIDADES:</p>
                <br /><p>Se qualquer cláusula deste documento for considerada ilegal, inválida ou inaplicável, no todo ou em parte, essa parte será considerada não fazendo parte dos Termos e Condições de Uso, sem afetar a legalidade, validade e aplicabilidade das demais cláusulas.</p>
                <br /><p>Vigência Os Termos de Uso entrarão em vigor para cada usuário da Quivon a partir do primeiro acesso à Plataforma e permanecerão em vigor até que o respectivo cadastro seja desativado, mantendo-se as responsabilidades durante o período de utilização.</p>
                <br /><p>RESOLUÇÃO DE CONFLITOS:</p>
                <br /><p> Estes Termos e Condições de Uso estão sujeitos às leis brasileiras e qualquer disputa decorrente deles será resolvida no foro da Comarca de São Paulo/SP, sendo este considerado competente para dirimir eventuais controvérsias, em detrimento de qualquer outro foro, por mais privilegiado que seja ou venha a ser. Sempre que possível, será dada prioridade às tentativas de resolução por meio de conciliação ou mediação.</p>
            </div>
        );
    }
}

export default Politica;
